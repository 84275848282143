import moment from "moment";
import {createSelector} from "reselect";

import {LoadingState} from "constants/redux";
import {AppState} from "redux/AppState";
import {cacheCreateSelectorPerUserId} from "redux/utils";
import {TIME_UNITS} from "constants/time";

import {Contact, ContactsMap} from "../contactTypes";
import {UserRole} from "@sense-os/goalie-js";
import {getContactHashIdFromPathname} from "../contactHelpers";
import {getAuthUser, getAuthUserAsContact} from "../../auth/redux";

/**
 * Returns array of contacts
 *
 * @param {AppState} state
 */
export const getAllContacts = createSelector(
	(state: AppState) => state.contacts.contactsMap,
	(contactsMap: ContactsMap): Contact[] => {
		return Object.keys(contactsMap).map((userId) => contactsMap[userId]);
	},
);

export const getContactsByRole = (role: UserRole) =>
	createSelector([getAllContacts], (contacts) => {
		return contacts.filter((contact) => contact.role === role);
	});

/**
 * Remove the next two functions getTherapistsContacts and getPatientsContacts,
 * once the function getContactsByRole above has a correct caching
 * instead of making a new createSelector every time it is called.
 */
export const getTherapistsContacts = createSelector([getAllContacts], (contacts) => {
	return contacts.filter((contact) => contact.role === UserRole.THERAPIST);
});

export const getPatientsContacts = createSelector([getAllContacts], (contacts) => {
	return contacts.filter((contact) => contact.role === UserRole.PATIENT);
});

export const getAllPatientsSortedByName = createSelector(getPatientsContacts, (contacts) =>
	contacts.sort((first, second) => {
		if (first.fullName.toLowerCase() < second.fullName.toLowerCase()) return -1;
		if (first.fullName.toLowerCase() > second.fullName.toLowerCase()) return 1;
		return 0;
	}),
);

export const getPatientsContactsSortedByName = createSelector(getPatientsContacts, (contacts) => {
	const sorted = [...contacts];
	sorted.sort((first, second) => {
		const a = first.fullName.toLowerCase();
		const b = second.fullName.toLowerCase();
		if (a < b) return -1;
		if (a > b) return 1;
		return 0;
	});

	return sorted;
});

export const getAllTherapistsSortedByName = createSelector(
	getAllContacts,
	getAuthUserAsContact,
	(contacts, authUser): Contact[] => {
		const therapists: Contact[] = contacts.filter((contact) => {
			return contact.role === UserRole.THERAPIST;
		});

		therapists.push(authUser);
		therapists.sort((first, second) => {
			if (first.fullName.toLowerCase() < second.fullName.toLowerCase()) return -1;
			if (first.fullName.toLowerCase() > second.fullName.toLowerCase()) return 1;
			return 0;
		});

		return therapists;
	},
);

export const getTreatmentTherapists = createSelector(
	(state: AppState) => state.contacts.treatmentTherapistsContactsMap,
	(contactsMap: ContactsMap): Contact[] => {
		return Object.keys(contactsMap).map((userId) => contactsMap[userId]);
	},
);

export const getTreatmentTherapistsSortedByName = createSelector(
	getTreatmentTherapists,
	getAuthUser,
	(
		contacts,
		authUser,
	): Array<{
		id: number;
		fullName: string;
		orgId: number;
	}> => {
		const therapists: Array<{id: number; fullName: string; orgId: number}> = contacts
			.filter((contact) => {
				return contact.role === UserRole.THERAPIST;
			})
			.map((contact) => ({...contact, orgId: contact.organization.id}));

		therapists.push({id: authUser.id, fullName: authUser.fullName, orgId: authUser.organization?.id});
		therapists.sort((first, second) => {
			if (first.fullName.toLowerCase() < second.fullName.toLowerCase()) return -1;
			if (first.fullName.toLowerCase() > second.fullName.toLowerCase()) return 1;
			return 0;
		});

		return therapists;
	},
);

export const getTherapistNameByIdFn = createSelector(
	(state: AppState) => state.contacts.treatmentTherapistsContactsMap,
	getAuthUser,
	(contacts, authUser) => {
		return (id: string) => {
			if (Number(id) === authUser.id) {
				return authUser.fullName;
			}

			const contact: Contact = contacts[id.toString()];
			return contact?.fullName || "";
		};
	},
);

/**
 * Find contact data by user id
 *
 * @param {AppState} state
 * @param {number} userId
 */
export function getContactById(state: AppState, userId: number): Contact {
	return state.contacts.contactsMap[userId];
}

export const getContactByIdFn = (userId: number) => (state: AppState) => {
	return state.contacts.contactsMap[userId];
};

/**
 * Get name of given user id
 */
export const getContactNameById = cacheCreateSelectorPerUserId((userId: number) => {
	return createSelector(
		(state: AppState) => getContactById(state, userId),
		(contact) => {
			return contact?.fullName || "...";
		},
	);
});

/**
 * Find contact data by user hashed id
 *
 * @param {AppState} state
 * @param {string} hashedID
 */
export function findContactByUserHashedId(state: AppState, hashedID: string): Contact {
	const contacts: Contact[] = getAllContacts(state);
	return contacts.find((client) => client.hashId === hashedID);
}

/**
 * Returns true if loading contacts is in progress
 *
 * @param {AppState} state
 */
export function isLoadingContacts(state: AppState): boolean {
	return state.contacts.loadingContacts === LoadingState.LOADING;
}

export function isContactsLoaded(state: AppState): boolean {
	return state.contacts.loadingContacts === LoadingState.LOADED;
}

export function getLoadingContacts(state: AppState): LoadingState {
	return state.contacts.loadingContacts;
}
/**
 * Return clients that have birthday between the given `date` & upcoming days.
 * @param {Date} date Date to be compared with client's birth day
 * @param {number} upcomingDay Number of upcoming days to be compared with client's birth day
 */
export const getClientsBirthDayByUpcomingDay = (date: Date, upcomingDay: number) =>
	createSelector(getContactsByRole(UserRole.PATIENT), (clients) =>
		clients.filter((client) => {
			if (!client.birthDate) {
				return false;
			}
			const dateToCompare = moment(date);
			const dateRangeLimit = moment(date).add(upcomingDay, TIME_UNITS.DAY);
			const birthDay = moment(client.birthDate).year(dateToCompare.year());
			const birthDayNextYear = moment(birthDay).year(dateToCompare.year() + 1);
			return (
				birthDay.isBetween(dateToCompare, dateRangeLimit, TIME_UNITS.DAY, "[]") ||
				// We also need to check birthday on the next year in case the nearest upcoming birthday is on the next year
				birthDayNextYear.isBetween(dateToCompare, dateRangeLimit, TIME_UNITS.DAY, "[]")
			);
		}),
	);

export const getPendingInvitations = (state: AppState) => state.contacts.pendingInvitations;
export const getPendingInvitationsCount = (state: AppState) => state.contacts.pendingInvitations.length;
export const getPendingInvitationsFetchingState = (state: AppState) => state.contacts.loadingInvitations;
export const getInvitationsLoadingState = (state: AppState, userId: number) =>
	state.contacts.invitationsLoadingMap[userId] || LoadingState.EMPTY;

export const getInvitationsLoadingStateOfId = cacheCreateSelectorPerUserId(
	(invitationId: number) => (state: AppState) =>
		state.contacts.invitationsLoadingMap[invitationId] || LoadingState.EMPTY,
);

/**
 * Get contact data from hashId in pathname
 */
export const getContactBySelectedClientId = (state: AppState) => {
	const selectedClientId = getSelectedContactId(state);

	if (selectedClientId) {
		return getContactById(state, selectedClientId);
	}
	return null;
};

/**
 * Get contacts map from state
 * @param state
 */
export const getContactsMap = (state: AppState) => {
	return state.contacts.contactsMap;
};

export const getSelectedContact = (state: AppState) => {
	const pathname = state.router.location.pathname;
	const hashId = getContactHashIdFromPathname(pathname);
	if (!hashId) {
		return null;
	}
	return findContactByUserHashedId(state, hashId);
};

export const getSelectedContactId = (state: AppState) => {
	const contact = getSelectedContact(state);
	return contact?.id;
};

/**
 * Return 'true' if 'removingContact' state is 'Loading'
 * @param {AppState} state
 * @returns {boolean}
 */
export const isRemovingContact = (state: AppState) => {
	return state.contacts.removingContact === LoadingState.LOADING;
};

export const getSelectedContactHashId = (state: AppState) => {
	const pathname = state.router.location.pathname;
	const hashId = getContactHashIdFromPathname(pathname);
	if (!hashId) {
		return null;
	}
	return hashId;
};

/**
 * Return `demoClientAccount` for product fruits demo purpose.
 */
export const getDemoClientHashId = createSelector(
	(_state: AppState, userEmail: string) => userEmail,
	getAllContacts,
	(userEmail, allContacts): string => {
		// Since `demoClientAccount` is automatically created by BE using registered therapist email address
		// with `+demo` suffix, here we want to find that `demoClientAccount` in contacts.
		// Example:
		// - Registered therapist email: john@niceday.nl
		// - Demo client account email would be: john+demo@niceday.nl
		// Related BE implementation: https://github.com/senseobservationsystems/goalie-backend/pull/3719
		const splittedUserEmail = userEmail?.split("@");
		splittedUserEmail?.splice(1, 0, "+demo@");
		const demoClientEmail: string = splittedUserEmail?.join("");

		const clientContacts = allContacts.filter((contact) => contact.role === UserRole.PATIENT);
		const demoClientContact = clientContacts.find((contact) => contact.email === demoClientEmail);

		return demoClientContact?.hashId || clientContacts.find((contact) => !!contact)?.hashId;
	},
);
