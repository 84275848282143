import {ActionType} from "typesafe-actions";
import {call, put, takeEvery} from "redux-saga/effects";
import {PresetItem, PresetItemsType, PresetPrefix} from "../views/PresetsFormContext";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {toastActions} from "../../toaster/redux";
import localization from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";
import {getFilteredFormValuesByPrefix} from "../helpers/presetHelpers";
import {presetsActions} from "../redux/presetsActions";
import {savePresetTrackerTask} from "./savePresetTrackerTask";
import {dialogActions} from "../../components/dialog/redux/dialogAction";
import {presetDialogName} from "../views/PresetsBaseDialog";
import {savePresetPsychoEducationTask} from "./savePresetPsychoEducationTask";
import {savePresetBehaviorExperimentTask} from "./savePresetBehaviorExperimentTask";
import {getTabMenuRoute} from "../../helpers/routeGenerator";
import {ContactTabPath, Path} from "app/Path";
import {history} from "../../helpers/routerHistory";

const log = createLogger("saveTaskSaga", SentryTags.Preset);

export function* savePreset(action: ActionType<typeof presetsActions.savePreset.request>) {
	const {formValues, userId, userHashId} = action.payload;

	try {
		/**
		 * Filtering the form values object into:
		 * 1. Tracker + Task
		 * 2. Psycho Education + Task
		 * 3. Thought Records + Task
		 * 4. Behavior Experiment + Task
		 * 5. ROM (OMQ & SMQ) (need to be discussed since the rom is still to be as a part of task)
		 */
		const formKeys: string[] = Object.keys(formValues);
		// filtering only the enabled form
		const enabledFormValues: PresetItemsType = formKeys.reduce((result, formKey) => {
			const currentFormValue: PresetItem = formValues[formKey];

			if (currentFormValue.isEnabled) {
				result[formKey] = currentFormValue;
			}

			return result;
		}, {});
		const enabledFormValueKeys: string[] = Object.keys(enabledFormValues);

		/** 1. Tracker + Task */
		const trackerFormValues: PresetItemsType = yield call(getFilteredFormValuesByPrefix, {
			formKeys: enabledFormValueKeys,
			formValues,
			prefix: PresetPrefix.TRACKER,
		});

		yield call(savePresetTrackerTask, {
			formValues: trackerFormValues,
			userId,
		});

		/** 2. Psycho Education + Task */
		const psychoEducationFormValues: PresetItemsType = yield call(getFilteredFormValuesByPrefix, {
			formKeys: enabledFormValueKeys,
			formValues,
			prefix: PresetPrefix.PSYCHO_EDUCATION,
		});

		yield call(savePresetPsychoEducationTask, {
			formValues: psychoEducationFormValues,
			userId,
		});

		/** 3. Thought Record + Task */
		const thoughtRecordFormValues: PresetItemsType = yield call(getFilteredFormValuesByPrefix, {
			formKeys: enabledFormValueKeys,
			formValues,
			prefix: PresetPrefix.THOUGHT_RECORD,
		});

		yield call(savePresetTrackerTask, {
			formValues: thoughtRecordFormValues,
			userId,
		});

		/** 4. Behavior Experiment + Task */
		const behaviorExperimentFormValues: PresetItemsType = yield call(getFilteredFormValuesByPrefix, {
			formKeys: enabledFormValueKeys,
			formValues,
			prefix: PresetPrefix.BEHAVIOR_EXPERIMENT,
		});

		yield call(savePresetBehaviorExperimentTask, {
			formValues: behaviorExperimentFormValues,
			userId,
		});

		yield put(presetsActions.savePreset.success());
		yield put(dialogActions.closeDialog(presetDialogName));
		yield put(
			toastActions.addToast({
				message: localization.formatMessage(strTranslation.MODULE_PRESETS.activating_module.succeed),
				type: "success",
			}),
		);

		const interventionRoutePage: string = getTabMenuRoute(
			Path.APP_CLIENT_DETAIL_MENU,
			userHashId,
			ContactTabPath.INTERVENTION,
		);
		history.push(interventionRoutePage);
	} catch (error) {
		log.captureException(error);
		yield put(presetsActions.savePreset.failure(error));
		yield put(
			toastActions.addToast({
				message: localization.formatMessage(strTranslation.MODULE_PRESETS.activating_module.failed),
				type: "error",
			}),
		);
	}
}

export default function* () {
	yield takeEvery(presetsActions.savePreset.request, savePreset);
}
