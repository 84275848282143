import {createSelector} from "reselect";
import {AppState as RootState} from "../AppState";
import {
	ActivityTypes,
	DiaryEntry,
	Emotions,
	EventFilter,
	EventViewData,
	EventViewType,
	ExpectedFeeling,
	ExpectedMood,
	Feeling,
	GScheme,
	Interval,
	Mood,
	PlannedEventEntry,
	PlannedEventStatus,
	SensorDatum,
	Sensors,
	TrackingEntry,
	UserTrackingData,
} from "./TrackingTypes";
import {isWithin} from "../../utils/time";
import {getSensorDataBySensorName} from "./TrackingHelper";
import {Target, TargetName} from "redux/sensorTarget/SensorTargetTypes";
import {getTargets} from "redux/sensorTarget/SensorTargetSelectors";
import {TSMap} from "typescript-map";
import {convertMoodScore} from "./TrackingHelper";
import {getSelectedContactId} from "../../../contacts/redux/contactSelectors";
import {ThoughtRecord} from "@sense-os/goalie-js";
import {getSelectedClientActivities} from "../../../clientActivity/redux/clientActivitySelectors";

//
// INTERVALS
//

/** Returns the tracking interval that is currently active for the current user */
export const getCurrentInterval = (state: RootState) => state.trackingData.currentInterval || undefined;

/**
 * Default value of `UserTrackingData`
 *
 * @see {UserTrackingData}
 */
const defaultUserTrackingDataMap: UserTrackingData = {
	data: new TSMap(),
	lastDataSync: new TSMap(),
	lastFetchedSensorDate: null,
	loadingState: new TSMap(),
};

/**
 * Returns `trackingDataMap` by `selectedClientId` state
 *
 * @see {UserTrackingData}
 */
export const getCurrentUserTrackingDataMap = (state: RootState): UserTrackingData => {
	const selectedClientId: number = getSelectedContactId(state);
	if (!selectedClientId) {
		return null;
	}
	return state.trackingData.userTrackingDataMap[selectedClientId] || defaultUserTrackingDataMap;
};

//
// RETRIEVE SENSOR DATA
//

/** Returns all sensor-data that is loaded for the current user */
export const getAllData = (state: RootState) => {
	const currentUserTrackingData: UserTrackingData = getCurrentUserTrackingDataMap(state);
	if (!currentUserTrackingData) {
		return null;
	}
	return currentUserTrackingData.data;
};

/** Creates a selector that returns the data of the specified sensor */
export const getSensorData = (sensor: Sensors) => {
	return (state: RootState) => getAllData(state) && getAllData(state).get(sensor); // || new TSMap();
};

/** Returns sensor data by sensor name and id */
export const getSensorDataById = (sensor: Sensors, id: string) => {
	return (state: RootState) => {
		if (!id) {
			return null;
		}
		const sensors = getSensorData(sensor)(state);
		return sensors && sensors.get(`${sensor}___${id}`);
	};
};

/** Returns currently visible emotions */
export const getActiveEmotions = (state: RootState) => state.trackingData.activeEmotions;

/** Returns the loading-state of the given sensor for the current interval */
export const getSensorLoadingState = (state: RootState, sensor: Sensors) => {
	const interval = getCurrentInterval(state);

	const currentUserTrackingData: UserTrackingData = getCurrentUserTrackingDataMap(state);
	if (!currentUserTrackingData) {
		return null;
	}

	const loading = interval && currentUserTrackingData.loadingState?.get(interval.id);
	return loading && loading.get(sensor);
};

/**
 * Generator fn to create a selector for a specific sensor.
 * Returned selector transforms sensor-data for sensor in the given interval to
 * a DataPoint that can be plotted by a graph.
 */
export const getCurrentSensorData = (sensor: Sensors) =>
	createSelector([getSensorData(sensor), getCurrentInterval], (sensorData, interval) => {
		if (!interval || !sensorData) {
			return undefined;
		}
		return (
			sensorData
				.values()
				// Filter `sensorData` by `startTime` and `endTime`
				// We only want `sensorData` between interval
				.filter((data) => {
					// SensorData startTime and endTime Timestamp
					const startTS: number = data.startTime.getTime(),
						endTS: number = data.endTime.getTime();

					return isWithinInterval(interval, startTS, endTS);
				})
				// Sort by `startTime` ascending
				.sort((a, b) => a.startTime.getTime() - b.startTime.getTime())
		);
	});

/**
 * Returns true if `starTS` and `endTS` is between `interval`
 *
 * @param {Interval} interval
 * @param {Number} startTS
 * @param {Number} endTS
 */
function isWithinInterval(interval: Interval, startTS: number, endTS: number): boolean {
	// Interval startTime and endTime Timestamp
	const intervalStartTS: number = interval!.start.getTime(),
		intervalEndTS: number = interval!.end.getTime();

	// Check startTime and endTime
	const isWithinStartTime: boolean = isWithin(startTS, intervalStartTS, intervalEndTS),
		isWithinEndTime: boolean = isWithin(endTS, intervalStartTS, intervalEndTS);

	return isWithinStartTime || isWithinEndTime;
}

//
// TRANSFORM SENSOR DATA
//

/**
 * Creates a reselect selector which will call the transformFn over all the data
 * loaded through the provided 'dataSelector' fn.
 */
export const transformSensorData = <T extends TrackingEntry, DP>(
	dataSelector: (state: RootState) => SensorDatum<T>[] | undefined,
	transformFn: (data: SensorDatum<TrackingEntry>) => DP,
) => createSelector([dataSelector], (data) => data && data.map(transformFn));

/**
 * Transform target to sensor data
 *
 * @param {TargetName} targetName
 * @param {(target: Target) => SensorDatum<T>} transformFn transformer function
 */
export function transformTargetToSensorData<T extends TrackingEntry>(
	targetName: TargetName,
	transformFn: (target: Target) => SensorDatum<T>,
): (state: RootState) => SensorDatum<T>[] {
	return (state: RootState) => {
		const targets: Target[] = getTargets(targetName)(state);
		const interval: Interval = getCurrentInterval(state);
		return (
			targets &&
			targets
				// Filter targets with current interval
				.filter(
					(target) =>
						(target.startTime.getTime() >= interval.start.getTime() &&
							target.startTime.getTime() <= interval.end.getTime()) ||
						(target.endTime.getTime() >= interval.start.getTime() &&
							target.endTime.getTime() <= interval.end.getTime()) ||
						(interval.start.getTime() >= target.startTime.getTime() &&
							interval.end.getTime() <= target.endTime.getTime()),
				)
				.map(transformFn)
				.sort((a, b) => a.startTime.getTime() - b.startTime.getTime())
		);
	};
}

export const getEventFilters = (state: RootState) => state.trackingData.eventFilters;

/**
 * `EventViewData` has a property called `sensors` that contains list of `sensor_name` ( @see {Sensors} ) exists inside an event.
 * We can use it to filter events using active EventFilters ( @see {EventFilter} ) in our state by loop through
 * every filter inside `EventFilter` and evaluate `event` with each filter criteria.
 */
export const getFilteredEventViewData = createSelector(
	[getSelectedClientActivities, getEventFilters],
	(eventViewData, eventFilters) => {
		// If there are no eventFilters selected, return all events
		if (eventFilters.length === 0) {
			return eventViewData;
		}
		// Loop through all events and filter it by `eventFilters`
		return eventViewData.filter((event) => {
			// Loop through `eventFilters` and evaluate `event` with each filter condition
			// If the `event` met criteria of one of the `eventFilters`, we show it.
			return eventFilters.some((filter) => isEventMeetFilterCriteria(event, filter));
		});
	},
);

/**
 * Evaluate if `event` meet `eventFilter` criteria.
 * Each `eventFilter` has their own criteria.
 *
 * @param {EventViewData} event
 * @param {EventFilter} eventFilter
 */
function isEventMeetFilterCriteria(event: EventViewData, eventFilter: EventFilter): boolean {
	const {sensors} = event;
	switch (eventFilter) {
		case EventFilter.MEETING_NOTE:
			// Search for `meeting_note` sensor_name
			return sensors.indexOf(Sensors.MEETING_NOTE) > -1;

		case EventFilter.G_SCHEME:
			// Search for `g_scheme` sensor_name
			return sensors.indexOf(Sensors.GSCHEME) > -1 || sensors.indexOf(Sensors.THOUGHT_RECORDS) > -1;

		case EventFilter.MOOD:
			// Search for `mood` or `check_in` sensor_name
			return sensors.indexOf(Sensors.MOOD) > -1 || sensors.indexOf(Sensors.CHECK_IN_FEELING) > -1;

		case EventFilter.ADVANCED_FEELING:
			// Search for `feeling` sensor_name
			return sensors.indexOf(Sensors.FEELING) > -1;

		case EventFilter.PSYCHO_EDUCATION:
			// Search for `psycho_education` sensor_name
			return sensors.indexOf(Sensors.PSYCHO_EDUCATION) > -1;

		case EventFilter.DIARY_ENTRY: {
			// Only PlannedEventSensor and RecurringPlannedEvent that have valid Diary entry value.
			// Therapy session may also have diary entry when it's completed, but we're treating it as a "placeholder". There's no use for it.
			const isValidEventType = [
				EventViewType.PLANNED_EVENT_SENSOR,
				EventViewType.RECURRING_PLANNED_EVENT,
				EventViewType.DIARY_ENTRY_SENSOR,
			].includes(event.type);
			const hasDiaryEntrySensor = sensors.includes(Sensors.DIARY);

			return isValidEventType && hasDiaryEntrySensor;
		}
		case EventFilter.BEHAVIOR_EXPERIMENT:
			// search for `behavior_experiment` sensor_name
			return sensors.indexOf(Sensors.BEHAVIOR_EXPERIMENT) > -1;

		//
		// PLANNED EVENT
		//
		case EventFilter.FUN_ACTIVITY:
			// Search for `planned_event` sensor_name and compare its `activityType`
			// to handle for the existing planned event with FUN_ACTIVITY, EXPOSURE_ACTIVITY, and OTHER_ACTIVITY `activity_type`
			// in the current implementation whenever create an "exercise" (aka planned_event) it would not set the `activity_type` property
			const isValidEventType = [
				ActivityTypes.FUN_ACTIVITY,
				ActivityTypes.EXPOSURE_ACTIVITY,
				ActivityTypes.OTHER_ACTIVITY,
			];
			return isValidEventType.includes(getActivityTypeFromEventViewData(event));
		case EventFilter.THERAPY_SESSION:
			// Search for `planned_event` sensor_name and compare its `activityType`
			return getActivityTypeFromEventViewData(event) === ActivityTypes.THERAPY_SESSION;
		case EventFilter.OMQ:
			// Search for `planned_event` sensor_name and compare its `activityType`
			return getActivityTypeFromEventViewData(event) === ActivityTypes.FILL_OMQ;
		case EventFilter.SMQ:
			// Search for `planned_event` sensor_name and compare its `activityType`
			return getActivityTypeFromEventViewData(event) === ActivityTypes.FILL_SMQ;

		default:
			return false;
	}
}

const plannedEventTypes = [
	EventViewType.SMQ_SENSOR,
	EventViewType.OMQ_SENSOR,
	EventViewType.PLANNED_EVENT_SENSOR,
	EventViewType.THERAPY_SESSION_SENSOR,
	EventViewType.RECURRING_PLANNED_EVENT,
	EventViewType.BEHAVIOR_EXPERIMENT,
	EventViewType.PSYCHO_EDUCATION,
];

/**
 * Returns true if event is a planned event
 * @param event
 */
function isPlannedEvent(event: EventViewData): boolean {
	return plannedEventTypes.includes(event.type);
}

const eventWithActivityTypes = [...plannedEventTypes, EventViewType.DIARY_ENTRY_SENSOR];

/**
 * Returns true if event has activity type
 * @param event
 */
function hasActivityType(event: EventViewData): boolean {
	return eventWithActivityTypes.includes(event.type);
}

/**
 * Return `activityType` from `EventViewData`
 *
 * @param {EventViewData} event
 */
export function getActivityTypeFromEventViewData(event: EventViewData): ActivityTypes | null {
	// Only planned events and diary entries have `activityType`,
	// Psycho education, Behavior Experiment is categorized as plan event but it doesn't have `activityType`.
	if (
		!hasActivityType(event) ||
		[EventViewType.PSYCHO_EDUCATION, EventViewType.BEHAVIOR_EXPERIMENT].includes(event.type)
	) {
		return null;
	}

	if (event.type === EventViewType.RECURRING_PLANNED_EVENT) {
		return event.source.payload.plannedEvent.activityType;
	}

	const sensor = event.source as SensorDatum<PlannedEventEntry | DiaryEntry>;
	return sensor.value.activityType;
}

/**
 * Get `diary_entry` sensor from `planned_event_entry` sensor
 *
 * @param {PlannedEvent} plannedEvent
 */
export function getDiaryEntryFromPlannedEvent(plannedEvent: PlannedEventEntry): DiaryEntry {
	const sensor = getSensorDataBySensorName<DiaryEntry>(plannedEvent, Sensors.DIARY);
	return sensor && sensor.value;
}

/**
 * Get `expected_feeling` sensor from `planned_event_entry` sensor
 *
 * @param {PlannedEvent} plannedEvent
 */
export function getExpectedFeelingFromPlannedEvent(plannedEvent: PlannedEventEntry): ExpectedFeeling {
	const sensor = getSensorDataBySensorName<ExpectedFeeling>(plannedEvent, Sensors.EXPECTED_FEELING);
	return sensor && sensor.value;
}

/**
 * Get `expected_mood` sensor from `planned_event_entry` sensor
 *
 * @param {PlannedEvent} plannedEvent
 */
export function getExpectedMoodFromPlannedEvent(plannedEvent: PlannedEventEntry): ExpectedMood {
	const sensor = getSensorDataBySensorName<ExpectedMood>(plannedEvent, Sensors.EXPECTED_MOOD);
	return sensor && sensor.value;
}

/**
 * Get `feeling` sensor from `diary_entry` sensor
 *
 * @param {DiaryEntry} diaryEntry
 */
export function getFeelingFromDiaryEntry(diaryEntry: DiaryEntry): Feeling {
	const sensor = getSensorDataBySensorName<Feeling>(diaryEntry, Sensors.FEELING);
	return sensor && sensor.value;
}

/**
 * Get `mood` sensor from `diary_entry` sensor
 *
 * @param {DiaryEntry} diaryEntry
 */
export function getMoodFromDiaryEntry(diaryEntry: DiaryEntry): Mood {
	const sensor = getSensorDataBySensorName<Mood>(diaryEntry, Sensors.MOOD);
	if (sensor && sensor.version === 1) {
		const convertedValue = convertMoodScore(sensor.value);
		return convertedValue;
	}
	return sensor && sensor.value;
}

/**
 * Get `gscheme` sensor from `diary_entry` sensor
 *
 * @param {DiaryEntry} diaryEntry
 */
export function getGSchemeFromDiaryEntry(diaryEntry: DiaryEntry): GScheme {
	const sensor = getSensorDataBySensorName<GScheme>(diaryEntry, Sensors.GSCHEME);
	return sensor && sensor.version === 1 && sensor.value;
}

/**
 * Get `thought` sensor from `diary_entry` sensor
 *
 * @param {DiaryEntry} diaryEntry
 */
export function getThoughtRecordFromDiaryEntry(diaryEntry: DiaryEntry): ThoughtRecord {
	const sensor = getSensorDataBySensorName<ThoughtRecord>(diaryEntry, Sensors.GSCHEME);
	return sensor && sensor.version > 1 && sensor.value;
}

/**
 * get feeling value by emotion
 *
 * @param {Feeling} feeling
 * @param {Emotions} emotion
 */
export function getFeelingValueByEmotion(feeling: Feeling, emotion: Emotions): number | null {
	return feeling[emotion];
}

/**
 * get status from eventViewData
 *
 * @param {EventViewData} eventViewData
 */
export function getStatusFromEventViewData(eventViewData: EventViewData): PlannedEventStatus {
	// Only planned events which have `status` value
	if (!isPlannedEvent(eventViewData)) {
		return null;
	}
	if (eventViewData.isCompleted) {
		return PlannedEventStatus.COMPLETE;
	}
	if (eventViewData.isCanceled) {
		return PlannedEventStatus.CANCELED;
	}
	return PlannedEventStatus.INCOMPLETE;
}

/**
 * get sensor from EventViewData
 */
export function getSensorFromEventViewData(eventViewData: EventViewData, sensor: Sensors): SensorDatum<any> {
	// TODO: Unhide this when we decided to show event with dailyPlannerAPI again
	// if(eventViewData.type === EventViewType.DAILY_PLANNER) {
	//     return getSensorDataBySensorName(eventViewData, sensor);
	// }
	const sourceSensor = eventViewData.source as SensorDatum<any>;
	if (sourceSensor.sensorName === sensor) {
		return sourceSensor;
	}
	return getSensorDataBySensorName(eventViewData, sensor);
}

// Get `lastDataSync` state
const getLastSync = (state: RootState) => {
	const currentUserTrackingData: UserTrackingData = getCurrentUserTrackingDataMap(state);
	if (!currentUserTrackingData) {
		return null;
	}

	return currentUserTrackingData.lastDataSync;
};

/**
 * Get last sync date by sensor
 */
export const getLastSyncBySensor = (sensor: Sensors) =>
	createSelector([getLastSync], (lastSync) => lastSync && lastSync.get(sensor));

// Get `lastFetchedSensorDate` state
export const getLastFetchedSensorDate = (state: RootState) => {
	const currentUserTrackingData: UserTrackingData = getCurrentUserTrackingDataMap(state);
	if (!currentUserTrackingData) {
		return null;
	}

	return currentUserTrackingData.lastFetchedSensorDate;
};

/**
 * get omq value from planned event entry
 *
 * @param {PlannedEventEntry} plannedEventEntry
 * @returns omq or smq
 */
export function getOmqSmqValueFromPlannedEvent(plannedEventEntry: PlannedEventEntry): any {
	return plannedEventEntry.reflection && plannedEventEntry.reflection.sensorData.value;
}
