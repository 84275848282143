import {put, call, takeEvery} from "redux-saga/effects";
import {GroupTherapyDetail, GroupTherapyMember, MemberListResponse, MemberRole} from "@sense-os/goalie-js";

import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import {getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {toastActions} from "../../toaster/redux";
import strTranslation from "../../assets/lang/strings";

import {groupTherapyActions} from "../redux/groupTherapyActions";
import groupTherapySDK from "../helpers/groupTherapySdk";
import {GroupTherapy} from "../groupTherapy";

const log = createLogger("fetchGroupTherapySaga", SentryTags.GroupTherapy);

function* fetchGroupTherapyList() {
	const token: string = yield call(getSessionId);

	try {
		// Get group therapy list
		const groupList: GroupTherapyDetail[] = yield apiCallSaga(groupTherapySDK.getGroupTherapyList, token);
		const groups: GroupTherapy[] = [];

		for (const i in groupList) {
			// Get group therapy member list
			const clients: MemberListResponse = yield apiCallSaga(
				groupTherapySDK.getGroupTherapyMemberList,
				token,
				groupList[i].id,
				MemberRole.CLIENT,
			);
			const therapists: MemberListResponse = yield apiCallSaga(
				groupTherapySDK.getGroupTherapyMemberList,
				token,
				groupList[i].id,
				MemberRole.THERAPIST,
			);
			const clientMembers: GroupTherapyMember[] = clients.results;
			const therapistMembers: GroupTherapyMember[] = therapists.results;

			groups.push({
				id: groupList[i].id,
				name: groupList[i].name,
				createdAt: groupList[i].createdAt,
				clients: clientMembers,
				therapists: therapistMembers,
			});
		}

		yield put(groupTherapyActions.fetchGroupTherapyList.success({groups}));
	} catch (err) {
		log.captureException(err, {message: "Failed to fetch group therapy list."});
		yield put(groupTherapyActions.fetchGroupTherapyList.failure(err));
		yield put(
			toastActions.addToast({message: strTranslation.USER_PROFILE.error_loading_data.toast, type: "error"}),
		);
	}
}

export default function* () {
	yield takeEvery(groupTherapyActions.fetchGroupTherapyList.request, fetchGroupTherapyList);
}
