import {ActionType} from "typesafe-actions";
import {behaviorExprActions} from "../redux/behaviorExprActions";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import createLogger from "../../../logger/createLogger";
import {call, put} from "redux-saga/effects";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {getBehaviorReminderDateTime} from "../helpers/behaviorExprHelpers";
import moment from "moment";
import {toastActions} from "../../../toaster/redux";
import localization from "../../../localization/Localization";
import strTranslation from "../../../assets/lang/strings";
import {onAutoEnableTracker} from "../../customTracker/helpers/onAutoEnableTracker";
import {CoreTrackerId} from "../../utils/coreTracker";
import {createBehaviorExperimentTask} from "./createBehaviorExperimentTask";
import {updateBehaviorExperimentTask} from "./updateBehaviorExperimentTask";

const log = createLogger("saveBehaviorExperimentTask", SentryTags.BehaviorExperiment);

/** Save Behavior Experiment Task */
export function* saveBehaviorExperimentTask(
	action: ActionType<typeof behaviorExprActions.saveBehaviorExprTask.request>,
) {
	const {userId, formValues, isEditing, isReplan, behaviorExprId, taskId} = action.payload;

	try {
		const token: string = yield call(getSessionId);

		// Set `remindedDateTime` to `null` if `reminderToggle` is not enabled
		const reminderDateTime: Date = yield call(getBehaviorReminderDateTime, formValues.reminderToggle && formValues);

		/** 1st Create Behavior Experiment */
		// Show warning toast when `reminderDateTime` is before creation date
		if (moment(reminderDateTime).isBefore()) {
			yield put(
				toastActions.addToast({
					message: localization.formatMessage(strTranslation.VALIDATION.reminder_must_be_in_future),
					type: "warning",
				}),
			);
			yield put(behaviorExprActions.saveBehaviorExprTask.failure(new Error()));
			return;
		}

		// handle for creation & replan
		if (!isEditing || isReplan) {
			yield call(createBehaviorExperimentTask, {
				formValues,
				reminderDateTime,
				token,
				userId,
			});
		}

		// handle for editing
		if (isEditing) {
			yield call(updateBehaviorExperimentTask, {
				behaviorExprId,
				taskId,
				formValues,
				reminderDateTime,
				token,
			});
		}

		/**
		 * Auto enable behavior experiment if it's disabled after create a new registration
		 */
		yield call(onAutoEnableTracker, {
			userId,
			trackerId: CoreTrackerId.BEHAVIOR_EXPERIMENT,
		});

		// reset the saved form value for the selected `userId`
		yield put(behaviorExprActions.resetFormValues(userId));
		yield put(behaviorExprActions.saveBehaviorExprTask.success({userId}));
		yield put(behaviorExprActions.closeForm());

		// the toast depends on whether creating or editing
		const selectedToast: string = isEditing
			? strTranslation.GRAPHS.new_event.plan.success_update.toast
			: strTranslation.GRAPHS.new_event.plan.success.toast;

		yield put(toastActions.addToast({message: selectedToast, type: "success"}));
	} catch (error) {
		log.error("Error while saving behavior experiment task data", error);
		yield put(toastActions.addToast({message: strTranslation.GRAPHS.new_event.plan.fail.toast, type: "error"}));
	}
}
