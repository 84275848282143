import {MeetingNote as BaseMeetingNote} from "@sense-os/sensor-schema/goalie-2-ts/meeting_note";
import {CheckIn as BaseCheckInFeeling} from "@sense-os/sensor-schema/goalie-2-ts/check_in";
import {Mood as BaseMood} from "@sense-os/sensor-schema/goalie-2-ts/mood";
import {Gscheme as BaseGScheme} from "@sense-os/sensor-schema/goalie-2-ts/gscheme";
import {Feeling as BaseFeeling} from "@sense-os/sensor-schema/goalie-2-ts/feeling";
import {Omq as BaseOmq} from "@sense-os/sensor-schema/goalie-2-ts/omq";
import {Smq as BaseSmq} from "@sense-os/sensor-schema/goalie-2-ts/smq";
import {TSMap} from "typescript-map";
import {LoadingState} from "../../constants/redux";
import {NumberMap} from "services/utils/Maps";
import {UserData, RecurringExpression, ThoughtRecord, PsychoEducationItem} from "@sense-os/goalie-js";
import {CustomTrackerSensor, TrackerDatum} from "../../../tracker/customTracker/customTrackerTypes";
import {BehaviorExperimentItem} from "@sense-os/goalie-js/dist/behaviorExperiment/types";
import {MeetInfo} from "../../../tracker/therapySession/types";

/** intervalId for fetched data: startTS_endTS */
export type IntervalId = string;

export enum TimeRange {
	FOUR_WEEKS = "four_weeks",
	WEEK = "week",
	DAY = "day",
	DAY_PART = "day_part",
}

/** Describing a period from start to end-time */
export interface Interval {
	id: IntervalId;
	/** start timestamp */
	start: Date;
	/** end timestamp */
	end: Date;
	/** size of the interval */
	range: TimeRange;
	/** sub-intervals that lay within this interval */
	children?: Interval[];
}

export type SensorData<T extends TrackingEntry> = SensorDatum<T>[];
export type SensorMap<T extends TrackingEntry> = TSMap<TrackingId, SensorDatum<T>>;
export type AllSensors = TSMap<Sensors, SensorMap<TrackingEntry>>;

/**
 * Sensor data wrapped with `%sensor_data`
 */
export interface ResolvedSensorData<T> {
	sensorData: SensorDatum<T>;
}

export type UserTrackingDataMap = NumberMap<UserTrackingData>;

export interface TrackingState {
	/** Duration of the data currently viewed */
	currentInterval: Interval | null;
	/** Array of event filters to show in events view */
	eventFilters: EventFilter[];
	/** Array of sensors that are viewed for this user */

	activeSensors: Sensors[];
	/** Array with the emotions that are currently displayed */
	activeEmotions: Emotions[];
	/** Tracking data mapped by user id */
	userTrackingDataMap: UserTrackingDataMap;
}

/** Tracking-data for one user */
export interface UserTrackingData {
	/** Map with all SensorData from all sensors */
	data: AllSensors;
	/** Loading state per interval per sensor */
	loadingState: TSMap<IntervalId, TSMap<Sensors, LoadingState>>;
	/** Last data sync date per sensor */
	lastDataSync: TSMap<Sensors, Date>;
	/** Last fetched sensor */
	lastFetchedSensorDate: Date;
}

/**
 * Similar to an event specified by tracking-sdk. Instead of 'relations' to
 * sensor-data, this object has actual references.
 */
export interface EventData {
	startTime: Date;
	endTime?: Date;
	data: SensorData<TrackingEntry>;
}

////////////////////////////////////////////////////////////////
//
// TRACKING DATA INTERFACES
// should be generated by tracking team based on JSonSchemas
//
////////////////////////////////////////////////////////////////

export type TrackingId = string;

export enum Sensors {
	AVERAGE_MOOD = "averageMood",
	DIARY = "diary_entry",
	EXPECTED_FEELING = "expected_feeling",
	EXPECTED_MOOD = "expected_mood",
	EXPECTED_GSCHEME = "expected_gscheme",
	FEELING = "feeling",
	GSCHEME = "gscheme",
	MEETING_NOTE = "meeting_note",
	MOOD = "mood",
	PLANNED_EVENT = "planned_event_entry",
	STEP_COUNT = "step_count",
	STEP_COUNT_TARGET = "step_count_target",
	OMQ = "omq",
	SMQ = "smq",
	CHECK_IN_FEELING = "check_in",

	// See https://github.com/senseobservationsystems/web-getgoalie/issues/1372#issuecomment-564464422
	// for reason of adding this THOUGHT_RECORDS entry
	THOUGHT_RECORDS = "thought_records",

	// Psycho education is not a sensor, adding this as sensor for event filtering purpose.
	PSYCHO_EDUCATION = "psycho_education",
	// Behavior Experiment is not a sensor, adding this as sensor for event filtering purpose.
	BEHAVIOR_EXPERIMENT = "behavior_experiment",
}

export const sourceName: string = "goalie_2";

export interface SensorConfig {
	name: string;
	version: number;
	sourceName: string;
}

/**
 * Sensor Data configuration map
 */
export const SensorConfigs: Record<string, SensorConfig> = {
	[Sensors.AVERAGE_MOOD]: {
		name: Sensors.AVERAGE_MOOD,
		version: 1,
		sourceName,
	},
	[Sensors.DIARY]: {
		name: Sensors.DIARY,
		version: 3,
		sourceName,
	},
	[Sensors.EXPECTED_FEELING]: {
		name: Sensors.EXPECTED_FEELING,
		version: 2,
		sourceName,
	},
	[Sensors.EXPECTED_MOOD]: {
		name: Sensors.EXPECTED_MOOD,
		version: 1,
		sourceName,
	},
	[Sensors.EXPECTED_GSCHEME]: {
		name: Sensors.EXPECTED_GSCHEME,
		version: 1,
		sourceName,
	},
	[Sensors.FEELING]: {
		name: Sensors.FEELING,
		version: 2,
		sourceName,
	},
	[Sensors.GSCHEME]: {
		name: Sensors.GSCHEME,
		version: 1,
		sourceName,
	},
	[Sensors.MEETING_NOTE]: {
		name: Sensors.MEETING_NOTE,
		version: 4,
		sourceName,
	},
	[Sensors.MOOD]: {
		name: Sensors.MOOD,
		version: 2,
		sourceName,
	},
	[Sensors.PLANNED_EVENT]: {
		name: Sensors.PLANNED_EVENT,
		version: 5,
		sourceName,
	},
	[Sensors.STEP_COUNT]: {
		name: Sensors.STEP_COUNT,
		version: 1,
		sourceName,
	},
	[Sensors.STEP_COUNT_TARGET]: {
		name: Sensors.STEP_COUNT_TARGET,
		version: 1,
		sourceName,
	},
	[Sensors.OMQ]: {
		name: Sensors.OMQ,
		version: 3,
		sourceName,
	},
	[Sensors.SMQ]: {
		name: Sensors.SMQ,
		version: 3,
		sourceName,
	},
};

export enum Emotions {
	HAPPY = "happy",
	CONFIDENT = "confident",
	MOTIVATED = "motivated",
	RELAXED = "relaxed",
	ENERGIZED = "energized",
	SAD = "sad",
	ANGRY = "angry",
	FEARFUL = "fearful",
	TENSE = "tense",
	PAINFUL = "painful",
}
export enum PlannedEventStatus {
	COMPLETE = "COMPLETED",
	// Rescheduled = "Rescheduled",
	CANCELED = "CANCELED",
	INCOMPLETE = "INCOMPLETED",
}
export enum ActivityTypes {
	FUN_ACTIVITY = "FUN_ACTIVITY",
	EXPOSURE_ACTIVITY = "EXPOSURE_ACTIVITY",
	OTHER_ACTIVITY = "OTHER_ACTIVITY",
	THERAPY_SESSION = "THERAPY_SESSION",
	FILL_OMQ = "FILL_OMQ",
	FILL_SMQ = "FILL_SMQ",
}
export enum ScheduleType {
	DIARY_ENTRY = "diary_entry_log",
	PLANNED_EVENT_SENSOR = "planned_event_entry",
	RECURRING_PLANNED_EVENT = "planned_event_log",
	MEETING_NOTE = "meeting_note",
	CHECK_IN_LOG = "checkin_log",
	DAILY_STEP_COUNT = "daily_step_count",
	THOUGHT_RECORDS = "gscheme_log",
}
export interface Feeling extends BaseFeeling {}
export interface ExpectedFeeling extends BaseFeeling {}
export interface Mood extends BaseMood {}
export interface ExpectedMood extends BaseMood {}
export interface GScheme extends BaseGScheme {}
export interface ExpectedGScheme extends BaseGScheme {}
export interface MeetingNote extends BaseMeetingNote {}
export interface Omq extends BaseOmq {}
export interface Smq extends BaseSmq {}
export interface CheckInFeeling extends BaseCheckInFeeling {}

export type StepCountEntry = number;
export type StepCountTarget = StepCountEntry;
export interface PlannedEventEntry {
	activityType?: ActivityTypes;
	title: string;
	description: string;
	feeling?: ResolvedSensorData<ExpectedFeeling>;
	mood?: ResolvedSensorData<ExpectedMood>;
	plannedOmq?: ResolvedSensorData<PlannedEventEntry>;
	plannedSmq?: ResolvedSensorData<PlannedEventEntry>;
	plannedFor: Date;
	status: PlannedEventStatus;
	reflection?: ResolvedSensorData<DiaryEntry | Smq | Omq>;
	shouldSendNotification: boolean;
	meetInfo?: MeetInfoEntry;
}

export interface MeetInfoEntry {
	meetType: MeetInfo;
	meetDetails: string;
}

export interface DiaryEntry {
	activityType?: ActivityTypes;
	title?: string;
	description: string;
	happenedAt: Date;
	feeling?: ResolvedSensorData<Feeling>;
	mood?: ResolvedSensorData<Mood>;
	gscheme?: ResolvedSensorData<ThoughtRecord>;
	trackers?: ResolvedSensorData<CustomTrackerSensor>[];
}

export interface CustomTracker {
	id: string;
	trackers: TrackerDatum;
}

export type TrackingEntry =
	| DiaryEntry
	| Feeling
	| ExpectedFeeling
	| GScheme
	| Mood
	| StepCountEntry
	| StepCountTarget
	| PlannedEventEntry
	| MeetingNote
	| ThoughtRecord
	| CheckInFeeling;

interface BaseDailyPlannerItem<Payload> {
	id?: string;
	scheduleType: ScheduleType;
	startTime: Date;
	endTime: Date;
	occurrenceTime: Date;
	isCompleted?: boolean;
	isCanceled?: boolean;
	title?: string;
	payload?: Payload;
	createdBy: number;
	updatedBy: number;
	createdAt: Date;
	updatedAt: Date;
	statusUpdatedAt?: Date;
}

export interface SensorPayload {
	sensors: ResolvedSensorData<PlannedEventEntry>[];
}

export interface RecurringPlannedEventPayload {
	plannedEvent: {
		id: string;
		title: string;
		description?: string;
		activityType?: ActivityTypes;
		createdAt: Date;
		updatedAt: Date;
		createdBy: number;
		updatedBy: number;
		owner: number;
		startTime: Date;
		endTime: Date;
		recurringExpression: RecurringExpression;
		expectedFeeling?: ResolvedSensorData<ExpectedFeeling>;
		reflection?: ResolvedSensorData<DiaryEntry>;
		cancelReason?: string;
	};
}

export interface RecurringPlannedEventDP extends BaseDailyPlannerItem<RecurringPlannedEventPayload> {
	scheduleType: ScheduleType.RECURRING_PLANNED_EVENT;
}

export interface PlannedEventSensorDP extends BaseDailyPlannerItem<SensorPayload> {
	scheduleType: ScheduleType.PLANNED_EVENT_SENSOR;
}

export type DailyPlannerItem = RecurringPlannedEventDP | PlannedEventSensorDP;

/**
 * This is a temporary interface for one sensor data,
 * since `SensorDataResponse` doesn't contains `createdBy` and `updatedBy`.
 *
 * @see {SensorDataResponse}
 */
export interface SensorDatum<T> {
	sensorName: Sensors;
	sourceName: string;
	startTime: Date;
	endTime: Date;
	id: string;
	userId: number;
	userProfile?: Partial<UserData>;
	value: T;
	version: number;
	createdAt: Date;
	updatedAt: Date;
	createdBy: number;
	createdByProfile?: Partial<UserData>;
	updatedBy?: number;
	updatedByProfile?: Partial<UserData>;
}

/**
 * EventViewType
 */
export enum EventViewType {
	DIARY_ENTRY_SENSOR = "DiaryEntrySensor",
	MEETING_NOTE_SENSOR = "MeetingNoteSensor",
	PLANNED_EVENT_SENSOR = "PlannedEventSensor",
	OMQ_SENSOR = "OMQSensor",
	SMQ_SENSOR = "SMQSensor",
	THERAPY_SESSION_SENSOR = "TherapySessionSensor",
	THOUGHT_RECORDS_SENSOR = "ThoughtRecordsSensor",
	BEHAVIOR_EXPERIMENT = "behaviorExperiment",
	RECURRING_PLANNED_EVENT = "RecurringPlannedEvent",
	PSYCHO_EDUCATION = "PsychoEducation",
	CHECK_IN_FEELING = "CheckInFeeling",
	CUSTOM_TRACKER = "CustomTracker",
}

interface BaseEventViewData<Source> {
	/** Client generated Id */
	id: string;
	/** Owner user id */
	ownerId: number;
	/** Either Diary Entry, Meeting Note, Planned Event, or DailyPlanner title  */
	title: string;
	/** Subtitle of the event view */
	subtitle?: string;
	/** StartTime of EventViewData */
	startTime: Date;
	/** EndTime of EventViewData */
	endTime: Date;
	/** type to identify `source` */
	type: EventViewType;
	/** list of Sensors inside `source` */
	sensors?: Sensors[];
	/** Raw data of `EventViewData` */
	source: Source;
	/** True if event is completed */
	isCompleted?: boolean;
	/** True if event is canceled */
	isCanceled?: boolean;
	/** Created by user id */
	createdBy?: number;
	/** Created by user profile */
	createdByProfile?: UserData;
	/** Updated by user id */
	updatedBy?: number;
	/** Updated by user profile */
	updatedByProfile?: UserData;
	/** Event created at */
	createdAt: Date;
	/** Event updated at */
	updatedAt: Date;
}

export interface PlannedEventSensorEventView extends BaseEventViewData<SensorDatum<PlannedEventEntry>> {
	type: EventViewType.PLANNED_EVENT_SENSOR;
}
export interface OMQEventView extends BaseEventViewData<SensorDatum<PlannedEventEntry>> {
	type: EventViewType.OMQ_SENSOR;
}
export interface SMQEventView extends BaseEventViewData<SensorDatum<PlannedEventEntry>> {
	type: EventViewType.SMQ_SENSOR;
}
export interface TherapySessionEventView extends BaseEventViewData<SensorDatum<PlannedEventEntry>> {
	type: EventViewType.THERAPY_SESSION_SENSOR;
}
export interface MeetingNoteSensorEventView extends BaseEventViewData<SensorDatum<MeetingNote>> {
	type: EventViewType.MEETING_NOTE_SENSOR;
}
export interface DiaryEntrySensorEventView extends BaseEventViewData<SensorDatum<DiaryEntry>> {
	type: EventViewType.DIARY_ENTRY_SENSOR;
}
export interface ThoughtRecordsSensorEventView extends BaseEventViewData<SensorDatum<ThoughtRecord>> {
	type: EventViewType.THOUGHT_RECORDS_SENSOR;
}
export interface RecurringPlannedEventView extends BaseEventViewData<RecurringPlannedEventDP> {
	type: EventViewType.RECURRING_PLANNED_EVENT;
	// actual date when the completion data is submited
	checkedOffAt: Date;
	// date of planned want to be completed or when the event is actually completed
	checkedOffFor: Date;
}
export interface PsychoEducationEventView extends BaseEventViewData<PsychoEducationItem> {
	type: EventViewType.PSYCHO_EDUCATION;
}
export interface BehaviorExprEventView extends BaseEventViewData<BehaviorExperimentItem> {
	type: EventViewType.BEHAVIOR_EXPERIMENT;
}
export interface CheckInFeelingSensorEventView extends BaseEventViewData<SensorDatum<CheckInFeeling>> {
	type: EventViewType.CHECK_IN_FEELING;
}

export interface CustomTrackerEventView extends BaseEventViewData<CustomTracker> {
	type: EventViewType.CUSTOM_TRACKER;
}

/**
 * Event view data model
 *
 * `EventViewData` is used to show data inside Events Segment.
 * Using this we can unify DailyPlanner and Sensor Data into array of `EventViewData`.
 * We use `EventViewType` to identify if `source` property is type of DailyPlanner or SensorData
 */
export type EventViewData =
	| PlannedEventSensorEventView
	| OMQEventView
	| SMQEventView
	| TherapySessionEventView
	| MeetingNoteSensorEventView
	| DiaryEntrySensorEventView
	| RecurringPlannedEventView
	| ThoughtRecordsSensorEventView
	| BehaviorExprEventView
	| PsychoEducationEventView
	| CheckInFeelingSensorEventView
	| CustomTrackerEventView;

/**
 * Event filter enums.
 *
 * @see {getFilteredEventViewData} for more details about how each filter works
 */
export enum EventFilter {
	FUN_ACTIVITY = "FUN_ACTIVITY",
	THERAPY_SESSION = "THERAPY_SESSION",
	DIARY_ENTRY = "DIARY_ENTRY",
	MOOD = "MOOD",
	ADVANCED_FEELING = "ADVANCED_FEELING",
	G_SCHEME = "G_SCHEME",
	MEETING_NOTE = "MEETING_NOTE",
	PSYCHO_EDUCATION = "PSYCHO_EDUCATION",
	BEHAVIOR_EXPERIMENT = "BEHAVIOR_EXPERIMENT",
	OMQ = "OMQ",
	SMQ = "SMQ",
	// OQ_45QUESTIONNAIRE = "OQ_45QUESTIONNAIRE",
	// STEPS = "STEPS",
}

/**
 * Event type in plan event sub menu.
 *
 */
export enum PlanEventType {
	ACTIVITY = "ACTIVITY",
	THERAPY_SESSION = "THERAPY_SESSION",
	BEHAVIOR_EXPERIMENT = "BEHAVIOR_EXPERIMENT",
	PSYCHO_EDUCATION = "PSYCHO_EDUCATION",
}
