import moment from "moment";
import {SensorData as BaseSensorData, SensorDataResponse} from "@sense-os/goalie-js";
import {PlannedEvent as BasePlannedEventEntry} from "@sense-os/sensor-schema/goalie-2-ts/planned_event";

import {
	SensorConfig,
	Sensors,
	SensorConfigs,
	ActivityTypes,
	PlannedEventStatus,
	SensorDatum,
	PlannedEventEntry,
	EventViewData,
	EventViewType,
} from "redux/tracking/TrackingTypes";
import {DISC} from "IoC/DISC";
import {TIME_UNITS} from "constants/time";
import loc from "../../../localization/Localization";

import {TherapySessionFormValues} from "../types";
import localization from "../../../localization/Localization";
import strTranslation from "../../../assets/lang/strings";
import {createEventViewId, getSensorNamesFromObject} from "redux/tracking/TrackingHelper";

/**
 * This function will transform `TherapySessionFormValues` to `BaseSensorData<BasePlannedEventEntry>`
 */
export async function transformSessionFormValuesToPlannedEventSensor(
	formValues: TherapySessionFormValues,
	plannedEventId: string,
): Promise<BaseSensorData<BasePlannedEventEntry>> {
	const {
		title,
		description,
		startTime,
		sessionDuration,
		reminderToggle,
		reminderDuration,
		reminderTimeUnit,
		meetInfo,
		meetDetails,
	} = formValues;

	// Convert to iso string with timezone e.g: `2018-05-31T10:49:12.000+02:00`
	const reminderTimeISOString: string = moment(startTime).subtract(reminderDuration, reminderTimeUnit).format();

	// Set sensor config as planned event entry
	const sensorConfig: SensorConfig = SensorConfigs[Sensors.PLANNED_EVENT];

	// Transform form values to planned event sensor data
	let plannedEventSensor: BaseSensorData<BasePlannedEventEntry> = {
		sourceName: sensorConfig.sourceName,
		sensorName: sensorConfig.name,
		startTime: startTime,
		endTime: moment(startTime).add(sessionDuration, TIME_UNITS.MINUTES).toDate(),
		version: sensorConfig.version,
		value: {
			title: title.trim(),
			description: description ? description.trim() : "",
			plannedFor: reminderTimeISOString,
			activityType: ActivityTypes.THERAPY_SESSION as any,
			status: PlannedEventStatus.INCOMPLETE,
			shouldSendNotification: reminderToggle,
			meetInfo: {
				meetType: meetInfo,
				meetDetails: meetDetails,
			},
		},
	};

	// Check if plannedEventId exists. (Means this is an update operation)
	if (plannedEventId) {
		const [previousPlannedEventSensor] =
			await DISC.getTrackingService().sdk.querySensorDataByIds<BasePlannedEventEntry>(
				[plannedEventId],
				SensorConfigs[Sensors.PLANNED_EVENT].sourceName,
			);

		if (!previousPlannedEventSensor) {
			throw new Error("Planned event does not exist:" + plannedEventId);
		}

		// Set plan event status to existing status
		plannedEventSensor.value.status = previousPlannedEventSensor.value.status;

		// Set plan event reflection to existing reflection
		if (previousPlannedEventSensor.value.reflection) {
			plannedEventSensor.value.reflection = previousPlannedEventSensor.value.reflection;
		}

		// Set version to existing version
		plannedEventSensor.version = previousPlannedEventSensor.version;
	}

	return plannedEventSensor;
}

/**
 * Set plannedOmq or plannedSmq as `BaseSensorData<BasePlannedEventEntry>` from THERAPY_SESSION's `BaseSensorData<BasePlannedEventEntry>`
 */
export function setOmqSmqToPlannedTherapySession(
	plannedTherapySession: BaseSensorData<BasePlannedEventEntry>,
	activityType: ActivityTypes,
): BaseSensorData<BasePlannedEventEntry> {
	const {startTime, endTime} = plannedTherapySession;

	// Here we want to set the time for when the questionnaire should occurs
	// For OMQ, we want the time to be 30 minutes BEFORE the START of the actual therapy session
	// For SMQ, we want the time to be 30 minutes AFTER the END of the actual therapy session
	const plannedTime =
			activityType === ActivityTypes.FILL_OMQ
				? moment(startTime).subtract(30, TIME_UNITS.MINUTES).toDate()
				: moment(endTime).add(30, TIME_UNITS.MINUTES).toDate(),
		plannedTimeISOString: string = moment(plannedTime).format();

	// The data of OMQ and SMQ is the same with the therapy session data
	// the only difference is the time of when the event will occur.
	// We also need to clear all unnecessary data from therapy session
	const plannedOmqSmq: BaseSensorData<BasePlannedEventEntry> = {
		...plannedTherapySession,
		startTime: plannedTime,
		endTime: plannedTime,
		value: {
			...plannedTherapySession.value,
			activityType,
			plannedFor: plannedTimeISOString,
			status: PlannedEventStatus.INCOMPLETE,
			plannedOmq: undefined,
			plannedSmq: undefined,
			reflection: undefined,
			feeling: undefined,
			gscheme: undefined,
			mood: undefined,
		},
	};

	return plannedOmqSmq;
}

/**
 * Create a new planned omq/smq
 */
export async function createPlannedOmqSmq(
	userId: number,
	plannedTherapySession: BaseSensorData<BasePlannedEventEntry>,
	activityType: ActivityTypes,
): Promise<SensorDataResponse<BasePlannedEventEntry>> {
	const plannedSessionWithOmqSmq = setOmqSmqToPlannedTherapySession(plannedTherapySession, activityType);
	return await DISC.getTrackingService().saveSensorData(plannedSessionWithOmqSmq, userId);
}

/**
 * Try to Save or Delete Planned Questionnaire (Planned SMQ or Planned OMQ)
 * If the planned questionnaire already exist, we want to maintain the questionnaire status and reflection
 * If the planned questionnaire does not exist, and the questionnaire option is not checked, we want to delete
 * the questionnaire (if already exist before).
 */
export async function updatePlannedOmqSmq(
	userId: number,
	plannedEvent: BaseSensorData<BasePlannedEventEntry>,
	activityType: ActivityTypes.FILL_SMQ | ActivityTypes.FILL_OMQ,
	isOptionChecked: boolean,
	existingPlannedQuestionnaire?: SensorDatum<PlannedEventEntry>,
): Promise<string | null> {
	try {
		// Option is not checked and no data to be REMOVED
		if (!isOptionChecked && !existingPlannedQuestionnaire) {
			return null;
		}

		// Option is checked and no data to be EDITED
		if (isOptionChecked && !existingPlannedQuestionnaire) {
			return (await createPlannedOmqSmq(userId, plannedEvent, activityType)).uri;
		}

		// Option is not checked and there is a data to be REMOVED
		if (!isOptionChecked && existingPlannedQuestionnaire) {
			await DISC.getTrackingService().sdk.deleteSensorData(existingPlannedQuestionnaire.id);
			return null;
		}

		// Option is checked and there is data to be EDITED
		const questionnaireRawSensor = setOmqSmqToPlannedTherapySession(plannedEvent, activityType);

		// Maintain planned questionnaire status
		questionnaireRawSensor.value.status = existingPlannedQuestionnaire.value.status;

		// Maintain planned questionnaire reflection if exist
		if (existingPlannedQuestionnaire.value.reflection) {
			questionnaireRawSensor.value.reflection = {
				"%uri": "niceday://sensor_data/" + existingPlannedQuestionnaire.value.reflection.sensorData.id,
			};
		}

		const questionnaireSensor = await DISC.getTrackingService().saveSensorData(
			questionnaireRawSensor,
			userId,
			existingPlannedQuestionnaire.id,
		);

		return questionnaireSensor.uri;
	} catch (err) {
		return null;
	}
}

/**
 * Return toast message when plan event is successfully saved
 */
export function getSavedSessionToastMessage(formValues: TherapySessionFormValues): string {
	const {startTime, endTime} = formValues;
	const dateText: string = moment(startTime).format("DD MMMM");
	const timeText: string = `${moment(startTime).format("HH:mm")} - ${moment(endTime).format("HH:mm")}`;

	return loc.formatMessage(strTranslation.GRAPHS.new_event.plan.THERAPY_SESSION.success.toast, {dateText, timeText});
}

/**
 * Get updated end time value from given `sessionStart` and `sessionDuration`
 */
export function getEndTimeValue(sessionStart: Date, sessionDuration: number): Date {
	return moment(sessionStart).add(sessionDuration, TIME_UNITS.MINUTES).toDate();
}

/**
 * This session is used to get the title of therapy session based on
 * @param fullName
 * @param isEditing
 * @returns the formatted title of therapy session
 */
export function getTherapySessionTitle(fullName: string, isEditing: boolean) {
	return localization.formatMessage(
		isEditing
			? strTranslation.GRAPHS.edit_event.therapy_session.header
			: strTranslation.GRAPHS.new_event.therapy_session.header,
		{
			name: fullName,
		},
	);
}

/**
 * Transform Sensor Datum Planned Event Entry to Event View Data
 * @param sensorSession
 * @returns a formatted EventViewData
 */
export const transformSessionSensorDatumToEventViewData = (
	sensorSession: SensorDatum<PlannedEventEntry>,
): EventViewData => {
	const type = EventViewType.THERAPY_SESSION_SENSOR;
	const sensorValue = sensorSession.value;
	const isCompleted = sensorValue.status === PlannedEventStatus.COMPLETE;
	const isCanceled = sensorValue.status === PlannedEventStatus.CANCELED;

	return {
		id: createEventViewId(type, sensorSession.id, sensorSession.startTime),
		ownerId: sensorSession.userId,
		title: sensorValue.title,
		startTime: sensorSession.startTime,
		endTime: sensorSession.startTime, // related to `transformPlannedEventSensorDP` function
		sensors: [sensorSession.sensorName, ...getSensorNamesFromObject(sensorSession.value)],
		type: type,
		source: sensorSession,
		isCompleted,
		isCanceled,
		createdAt: sensorSession.createdAt,
		updatedAt: sensorSession.updatedAt,
		createdBy: sensorSession.createdBy,
		updatedBy: sensorSession.updatedBy,
	};
};
