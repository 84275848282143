import {fork} from "redux-saga/effects";
import fetchGroupTherapySaga from "./fetchGroupTherapySaga";
import fetchGroupRegistrationListSaga from "./fetchGroupRegistrationListSaga";
import saveGroupTherapySaga from "./saveGroupTherapySaga";
import editGroupTherapySaga from "./editGroupTherapySaga";
import deleteGroupTherapySaga from "./deleteGroupTherapySaga";

export default function* () {
	yield fork(fetchGroupTherapySaga);
	yield fork(fetchGroupRegistrationListSaga);
	yield fork(saveGroupTherapySaga);
	yield fork(editGroupTherapySaga);
	yield fork(deleteGroupTherapySaga);
}
