/**
 * The FreshChat implementation is limited to this one file only.
 * Based on the limited documentation in https://developers.freshchat.com/web-sdk/v1/
 */

import {FC, useEffect} from "react";
import {getSessionId} from "../auth/helpers/authStorage";

import freshdeskSdk from "../freshdesk/app/freshdeskSdk";
import useAuthUser from "../auth/hooks/useAuthUser";
import {AppConfig} from "app/AppConfig";
import localization from "../localization/Localization";

// A hack so we don't have to set `window as any` everywhere for FreshChat.
const anyWindow = window as any;

/**
 * Some initialization steps are async.
 * This "ID" below is a helper to see decide
 * whether the initialization steps should continue or abort.
 *
 * This one is combination of user id and timestamp.
 */
let currentFreshChatUserId: string = null;

/** Get signed JWT from backend and authenticate to FreshChat. */
async function signJwtAndAuthenticate(uuid: string) {
	const token = getSessionId();
	const jwtResponse = await freshdeskSdk.createFreshchatJwt(token, {freshchatUuid: uuid});
	anyWindow.fcWidget?.authenticate(jwtResponse.token);
}

/** Destory the freshchat component and its user data. */
function destroyFreshChat() {
	if (!anyWindow.fcWidget) return;
	anyWindow.fcWidget.user?.clear();
	anyWindow.fcWidget.destroy();
}

/** Callback that listen to user:change event from FreshChat. */
function onUserChange(freshChatUserId) {
	return (data) => {
		// Ignore the listener and destroy everything if it's not the same id anymore
		if (freshChatUserId !== currentFreshChatUserId) {
			unloadFreshChat(freshChatUserId);
			return;
		}

		/**
		 * Right now, we only cares when the user is not correctly authenticated yet.
		 * And there are different ways of getting uuid depending on the status.
		 */
		if (!data?.success && data?.status === 401) {
			signJwtAndAuthenticate(data?.data?.freshchat_uuid);
		} else if (
			data?.data?.userState === "not_loaded" ||
			data?.data?.userState === "unloaded" ||
			data?.data?.userState === "not_created" ||
			data?.data?.userState === "not_authenticated"
		) {
			anyWindow.fcWidget.user.getUUID().then((resp) => {
				if (!resp?.data?.uuid) return;
				signJwtAndAuthenticate(resp?.data?.uuid);
			});
		}
	};
}

function attachHandler(helperFreshChatUserId: string) {
	anyWindow.fcWidget?.on("user:statechange", onUserChange(helperFreshChatUserId));
}

function detachHandler() {
	anyWindow.fcWidget?.off("user:statechange", onUserChange);
}

/** Will be triggered once we finish getting FreshChat script. */
function loadFreshChat(freshChatUserId: string, externalId: string) {
	return () => {
		// Destroy everything if it's not the same id anymore
		// after loading the FreshChat script.
		if (freshChatUserId !== currentFreshChatUserId) {
			unloadFreshChat(freshChatUserId);
			return;
		}

		attachHandler(freshChatUserId);
		anyWindow.fcWidgetMessengerConfig = {
			config: {
				headerProperty: {
					hideChatButton: true,
				},
			},
		};

		anyWindow.fcWidget.setExternalId(externalId);
		anyWindow.fcWidget.init({
			token: AppConfig.FRESHCHAT_KEY,
			host: "https://niceday.freshchat.com",
			externalId: externalId,
			locale: localization.getLocale(),
		});
	};
}

/** Will be triggered whenever the useEffect in component below is re-triggered. */
function unloadFreshChat(freshChatUserId: string) {
	return () => {
		currentFreshChatUserId = "";
		detachHandler();
		destroyFreshChat();
		document.getElementById(freshChatUserId)?.remove();
	};
}

/**
 * Wrapper for FreshChat widget.
 */
export const FreshdeskChat: FC<{}> = () => {
	const authUser = useAuthUser();

	useEffect(() => {
		if (!authUser?.id || !authUser?.externalId) {
			// Do nothing when user is logged out.
			return;
		}

		currentFreshChatUserId = new Date().getTime().toString();

		// Load FreshChat scripts.
		// Unfortunately, we can't just download it once and setup users
		// multiple times later.
		// Have to load them each time we set a new user.
		const e = document.createElement("script");
		e.id = currentFreshChatUserId;
		e.async = true;
		e.src = "https://niceday.freshchat.com/js/widget.js";
		e.onload = loadFreshChat(currentFreshChatUserId, authUser.externalId);
		document.head.appendChild(e);

		return unloadFreshChat(currentFreshChatUserId);

		// This should makes sure that we only ever trigger this effect again if authUser changes.
	}, [authUser?.id, authUser?.externalId]);

	return <></>;
};
