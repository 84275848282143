import {ForbiddenError, InternalServerError} from "@sense-os/goalie-js";
import {DistributionResponse, LastUploadedDate, PeriodType} from "@sense-os/goalie-js/dist/tracking/";
import moment from "moment";
import {Dispatch} from "redux";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {ActionType, createAction} from "typesafe-actions";

import {Path} from "app/Path";
import {contactActions} from "../../../contacts/redux/contactAction";
import featureFlags from "../../../featureFlags/FeatureFlags";
import {history} from "visual/App";

import {TIME_UNITS} from "../../constants/time";
import {DISC} from "../../IoC/DISC";
import Localization from "../../../localization/Localization";

import {AppState as RootState, AppState} from "../AppState";
import {SensorTargetActions} from "../sensorTarget/SensorTargetActions";
import {TargetName} from "../sensorTarget/SensorTargetTypes";

import {transformSensorDistributionToSensorData} from "./TrackingHelper";
import {
	Emotions,
	EventFilter,
	Interval,
	IntervalId,
	SensorData,
	SensorDatum,
	Sensors,
	StepCountEntry,
	TrackingEntry,
	EventViewData,
} from "./TrackingTypes";
import {getCurrentInterval} from "./TrackingSelector";
import {toastActions, ToastActionTypes} from "../../../toaster/redux/toastAction";
import {apiCall} from "../../../helpers/apiCall/apiCall";
import {getSelectedContactId} from "../../../contacts/redux/contactSelectors";
import {clientActivityActions, ClientActivityActionType} from "../../../clientActivity/redux/clientActivitiyActions";
import strTranslation from "../../../assets/lang/strings";

//
// LOADING SENSOR DATA
//

/** Request data of all active sensors to be loaded in given interval */
export const loadingSensor = createAction(
	"LOADING_SENSOR",
	(userId: number, intervalId: IntervalId, sensor: Sensors) => ({
		userId,
		intervalId,
		sensor,
	}),
)();

/** Data of specified sensor with specified interval is loaded */
export const loadedSensor = createAction(
	"LOADED_SENSOR",
	(userId: number, intervalId: IntervalId, sensor: Sensors, data: SensorData<TrackingEntry>) => ({
		userId,
		intervalId,
		sensor,
		data,
	}),
)();

/** Error loading data of specified sensor with specified interval */
export const errorLoadingSensor = createAction(
	"ERROR_LOADING_SENSOR",
	(userId: number, intervalId: IntervalId, sensor: Sensors, e: any) => ({
		userId,
		intervalId,
		sensor,
		e,
	}),
)();

//
// LOAD DATA LAST SYNC
//
/**
 * Action to indicates request data last sync is in progress
 *
 * @param {Sensors[]} sensorNames
 * @param {Number} userId
 */
export const loadingDataLastSync = createAction("LOADING_DATA_LAST_SYNC", (userId: number, sensorNames: Sensors[]) => ({
	sensorNames,
	userId,
}))();

/**
 * Action to store loaded data last sync to reducer
 *
 * @param {LastUploadedDate[]} results
 */
export const loadedDataLastSync = createAction(
	"LOADED_DATA_LAST_SYNC",
	(userId: number, results: LastUploadedDate[]) => ({userId, results}),
)();

/**
 * Action to indicate request data last sync has failed
 */
export const errorLoadingDataLastSync = createAction("ERROR_LOADING_DATA_LAST_SYNC", (userId: number, e: any) => ({
	userId,
	e,
}))();

/**
 * Thunk Action to request data last sync from server by sensor names
 *
 * @param {Sensors} sensorNames
 */
export function loadDataLastSync(
	sensorNames: Sensors[] = [Sensors.STEP_COUNT, Sensors.FEELING, Sensors.MOOD],
): ThunkAction<void, AppState, any, TrackingAction> {
	return (dispatch, getState) => {
		const userId: number = getSelectedContactId(getState());

		dispatch(loadingDataLastSync(userId, sensorNames));
		const trackingSDK = DISC.getTrackingService().sdk;
		apiCall([trackingSDK, trackingSDK.getLastUploadedDate], {
			sensorNames,
			userId,
		})
			.then((lastUploadedDates: LastUploadedDate[]) => dispatch(loadedDataLastSync(userId, lastUploadedDates)))
			.catch((err) => {
				dispatch(errorLoadingDataLastSync(userId, err));
				errorHandler(userId, err, dispatch);
			});
	};
}

/**
 * 1. Looks up the active sensors in the app-state.
 * 2. For each active sensor, it will check if the data for the interval exists
 *  2.a if exists, do nothing
 *  2.b load data from tracking-sdk for specific sensor
 *    - dispatch LOADED_SENSOR with loaded data
 */
export const loadActiveSensors = (interval: Interval, userId: number) => {
	return (dispatch: Dispatch<TrackingAction>, getState: () => RootState) => {
		const activeSensors = getState().trackingData.activeSensors;

		if (!interval) {
			return Promise.resolve();
		}

		return Promise.all(
			activeSensors.map(async (sensor) => {
				dispatch(loadingSensor(userId, interval.id, sensor));

				try {
					const sensorResolved = await apiCall(
						DISC.getTrackingService().getSensorResolved,
						[sensor],
						interval.start.toISOString(),
						interval.end.toISOString(),
						userId,
					);
					dispatch(loadedSensor(userId, interval.id, sensor as Sensors, sensorResolved));

					return sensorResolved;
				} catch (err) {
					dispatch(errorLoadingSensor(userId, interval.id, sensor, err));
					errorHandler(userId, err, dispatch);

					// If something's wrong when trying to fetch sensor, we need to return empty array
					// So the loading user profiles procedure won't error too
					return [];
				}
			}),
		);
	};
};

//
// LOAD EVENT VIEW DATA
//
/** EventViewData are being loaded */
export const loadingEventViewData = createAction("LOADING_EVENT_VIEW_DATA", (intervalId: string, userId: number) => ({
	intervalId,
	userId,
}))();
/** EventViewData are loaded */
export const loadedEventViewData = createAction(
	"LOADED_EVENT_VIEW_DATA",
	(intervalId: string, userId: number, eventViewDataList: EventViewData[]) => ({
		intervalId,
		userId,
		eventViewDataList,
	}),
)();
/** Failed to load EventViewData */
export const errorLoadingEventViewData = createAction(
	"ERROR_LOADING_EVENT_VIEW_DATA",
	(intervalId: string, userId: number, err: any) => ({intervalId, userId, err}),
)();

/** Loads both sensor-data and events for the given interval */
export const loadAllData = (interval: Interval, userId: number) => (dispatch: TrackingThunkDispatch) => {
	dispatch(loadActiveSensors(interval, userId));

	if (featureFlags.dataLastSync) {
		// For now the BE only supports STEP COUNT sensor
		dispatch(loadDataLastSync([Sensors.STEP_COUNT]));
	}
	if (featureFlags.stepCount) {
		dispatch(getDistribution(interval, userId, Sensors.STEP_COUNT));
	}
	// Load dailyplanner
	// We don't want to show dailyplanners generated by logger configuration for now
	// dispatch(loadDailyPlanner(interval, userId));

	// Load stepcount target
	// TODO: move this if the new 3rd version (core tracker) has been implemented,
	// after the implementation, step count cart can be hidden
	dispatch(SensorTargetActions.loadActiveTarget(userId, TargetName.STEP_COUNT));
	dispatch(SensorTargetActions.loadTargetHistories(interval, userId, TargetName.STEP_COUNT));
};

//
// CHANGING TIME RANGE
//

/** Change the interval of data that is being viewed by user */
export const setCurrentInterval = createAction("SET_INTERVAL", (interval: Interval) => ({interval}))();

/**
 * Given a function to modify interval, return a thunk action that apply this
 * interval modification function.
 */
export const applyIntervalModification = (fn: (prevInterval: Interval) => Interval) => {
	return (dispatch: TrackingThunkDispatch, getState: () => RootState) => {
		const curInterval = getCurrentInterval(getState());
		dispatch(clientActivityActions.changeInterval(fn(curInterval)));
	};
};

//
// TOGGLE EMOTION ON/OFF
//

export const toggleEmotion = createAction("TOGGLE_EMOTION", (feeling: Emotions, on: boolean) => ({feeling, on}))();

//
// TOGGLE EVENT FILTER
//

export const toggleEventFilter = createAction("TOGGLE_EVENT_FILTER", (filter: EventFilter, on: boolean) => ({
	filter,
	on,
}))();

//
// SENSOR DISTRIBUTION API
//
/**
 * Get sensor distribution
 *
 * This function calls Sensor Distribution API which basically
 * aggregates sensor data from a specific interval.
 * The results then get converted to SensorData format by mocking some values.
 * @see {TrackingService.getSensorDistribution}
 *
 * @param {Interval} interval
 * @param {number} userId
 * @param {Sensors} sensor
 */
function getDistribution(interval: Interval, userId: number, sensor: Sensors): TrackingThunkAction {
	return (dispatch) => {
		dispatch(loadingSensor(userId, interval.id, sensor));
		const trackingSDK = DISC.getTrackingService().sdk;
		apiCall([trackingSDK, trackingSDK.getDistribution], {
			startTime: interval.start,
			endTime: moment(interval.end).endOf(TIME_UNITS.DAY).toDate(),
			periodType: PeriodType.DAILY,
			userId,
			sensorName: sensor,
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		})
			.then((distributions) => {
				if (sensor === Sensors.STEP_COUNT) {
					// convert to sensorData since Step count components relies on SensorDatum<T>
					const stepCountSensors: SensorDatum<StepCountEntry>[] = transformSensorDistributionToSensorData<
						number,
						StepCountEntry
					>(distributions as DistributionResponse<StepCountEntry>[], sensor, userId, (val) => val);
					dispatch(loadedSensor(userId, interval.id, sensor, stepCountSensors));
				}
			})
			.catch((err) => {
				dispatch(errorLoadingSensor(userId, interval.id, sensor, err));
				errorHandler(userId, err, dispatch);
			});
	};
}

/**
 * Handle Request error for all requests inside this file
 *
 * @param {any} err
 */
function errorHandler(userId: number, err: any, dispatch: Dispatch<any>): void {
	if (!err) {
		return;
	}

	if (err instanceof ForbiddenError) {
		// Client might already disconnected with therapist.
		// Thus we need to redirect therapist to dashboard and refetch clients

		// Show warning message
		const toastMessage = Localization.formatMessage(strTranslation.GRAPHS.toast.error.forbidden);
		dispatch(toastActions.addToast({message: toastMessage, type: "warning"}));

		// Refetch client
		// DISC.getSenseNetService().fetchClients(); //deprecated;
		dispatch(contactActions.loadContactById(userId));

		// Redirect therapist
		history.push(Path.APP);
		return;
	}

	if (err instanceof InternalServerError) {
		// Something wrong with the BE, show error message
		const toastMessage = Localization.formatMessage(
			strTranslation.COMMON.toast.error.internal_server_error.cannot_load_data,
		);
		dispatch(toastActions.addToast({message: toastMessage, type: "error"}));
		return;
	}
}

//
// EXPORT ACTION TYPES
//
// Helper exports to allow our TS-reducer to use strongly typed action objects.
//

const actions = {
	setCurrentInterval,
	loadingSensor,
	loadedSensor,
	errorLoadingSensor,
	toggleEmotion,
	loadingDataLastSync,
	loadedDataLastSync,
	errorLoadingDataLastSync,
	toggleEventFilter,
	loadingEventViewData,
	loadedEventViewData,
	errorLoadingEventViewData,
};

export type TrackingAction = ActionType<typeof actions>;
type TrackingThunkAction = ThunkAction<void, AppState, any, TrackingAction | ToastActionTypes>;
type TrackingThunkDispatch = ThunkDispatch<AppState, any, TrackingAction | ClientActivityActionType>;
