import {call, put, takeEvery} from "redux-saga/effects";
import {GroupTherapyRegistration, RegistrationType} from "@sense-os/goalie-js";
import {ActionType} from "typesafe-actions";
import moment from "moment";

import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";

import {groupTherapyActions} from "../redux/groupTherapyActions";
import {GroupRegistrationItem} from "../groupTherapy";
import {convertRegistrationListIntoRegistrationItems} from "../helpers/groupTherapyHelpers";

const log = createLogger("fetchGroupRegistrationListSaga", SentryTags.GroupTherapy);

// TODO Remove when backend data is ready in upcoming PR
const mockRegistrations: GroupTherapyRegistration[] = [
	{
		id: 1,
		title: "Notes registration",
		dueDate: moment().startOf("day").subtract(1, "day").toDate(),
		type: RegistrationType.SESSION_NOTE,
	},
	{
		id: 2,
		title: "Behavior experiment",
		dueDate: moment().startOf("day").set("hour", 16).toDate(),
		type: RegistrationType.BEHAVIOR_EXPERIMENT,
	},
	{
		id: 3,
		title: "Session 2 - This is an awkwardly long title with 60 characters",
		dueDate: moment().startOf("day").add(1, "day").set("hour", 14).toDate(),
		type: RegistrationType.SESSION,
	},
	{
		id: 4,
		title: "Daily mood registration",
		dueDate: moment().startOf("day").add(1, "day").toDate(),
		type: RegistrationType.SESSION_NOTE,
	},
	{
		id: 5,
		title: "Psychoeducation: Anxiety",
		dueDate: moment().startOf("day").add(1, "day").toDate(),
		type: RegistrationType.HOMEWORK,
	},
];

function* fetchGroupRegistrations(action: ActionType<typeof groupTherapyActions.fetchGroupRegistrationList.request>) {
	const {id} = action.payload;

	try {
		//
		// TODO add apiCallSaga when backend is ready
		//
		const registrationItems: GroupRegistrationItem[] = yield call(
			convertRegistrationListIntoRegistrationItems,
			mockRegistrations,
		);

		yield put(groupTherapyActions.fetchGroupRegistrationList.success({id, registrations: registrationItems}));
	} catch (err) {
		log.captureException(err, {message: "Unable to fetch group registration list."});
		yield put(groupTherapyActions.fetchGroupRegistrationList.failure(err));
	}
}

export default function* () {
	yield takeEvery(groupTherapyActions.fetchGroupRegistrationList.request, fetchGroupRegistrations);
}
