import {ArticleRequestCreate, PsychoEducationItem} from "@sense-os/goalie-js";
import {createAction, ActionType, createAsyncAction} from "typesafe-actions";
import {PsychoEducationArticle, PsychoEducationFormValues} from "../psychoEducationTypes";

export const psychoEducationActions = {
	//
	// Form dialog actions
	//
	openForm: createAction("psychoEducationActions/openForm", (userId: number, id?: number) => ({
		userId,
		id,
	}))(),
	closeForm: createAction("psychoEducationActions/closeForm")(),

	//
	// Save form values actions
	//
	saveFormValues: createAction(
		"psychoEducationActions/saveFormValues",
		(userId: number, formValues?: PsychoEducationFormValues) => ({userId, formValues}),
	)(),

	//
	// Save psycho education actions
	//
	savePsychoEducation: createAsyncAction(
		"psychoEducationActions/savePsychoEducation",
		"psychoEducationActions/savePsychoEducationSucceed",
		"psychoEducationActions/savePsychoEducationFailed",
	)<{userId?: number; formValues: PsychoEducationFormValues}, {userId?: number}, Error>(),

	//
	// Save psycho education actions
	//
	savePsychoEducationTask: createAsyncAction(
		"psychoEducationActions/savePsychoEducationTask",
		"psychoEducationActions/savePsychoEducationTaskSucceed",
		"psychoEducationActions/savePsychoEducationTaskFailed",
	)<
		{userId: number; formValues: PsychoEducationFormValues; psychoEducationId: number; taskId: number},
		{userId?: number},
		Error
	>(),

	//
	// Save psycho education articles actions
	//
	saveArticles: createAsyncAction(
		"psychoEducationActions/saveArticles",
		"psychoEducationActions/saveArticlesSucceed",
		"psychoEducationActions/saveArticlesFailed",
	)<{psychoEducationId: number; articlesBody: ArticleRequestCreate[]}, void, Error>(),

	//
	// Update psycho education articles actions
	//
	updateArticles: createAsyncAction(
		"psychoEducationActions/updateArticles",
		"psychoEducationActions/updateArticlesSucceed",
		"psychoEducationActions/updateArticlesFailed",
	)<{psychoEducationId: number; articles: PsychoEducationArticle[]}, void, Error>(),

	//
	// Remove psycho education articles actions
	//
	removeArticles: createAsyncAction(
		"psychoEducationActions/removeArticles",
		"psychoEducationActions/removeArticlesSucceed",
		"psychoEducationActions/removeArticlesFailed",
	)<{psychoEducationId: number; articleIds: number[]}, void, Error>(),

	//
	// Set article id to be removed action
	//
	setRemovedArticleId: createAction("psychoEducationActions/setRemovedArticleId", (articleIds: number[]) => ({
		articleIds,
	}))(),

	//
	// fetch psycho education actions
	//
	fetchPsychoEducation: createAsyncAction(
		"psychoEducationActions/fetchPsychoEducation",
		"psychoEducationActions/fetchPsychoEducationSucceed",
		"psychoEducationActions/fetchPsychoEducationFailed",
	)<{userId: number; id: number}, {psychoEducationData: PsychoEducationItem}, Error>(),

	//
	// delete psycho education actions
	//
	deletePsychoEducation: createAsyncAction(
		"psychoEducationActions/deletePsychoEducation",
		"psychoEducationActions/deletePsychoEducationSucceed",
		"psychoEducationActions/deletePsychoEducationFailed",
	)<{id: number; taskId: number}, void, Error>(),
};

export type PsychoEducationActionType = ActionType<typeof psychoEducationActions>;
