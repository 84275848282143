import {GroupTherapy} from "@sense-os/goalie-js";

const groupTherapySDK = new GroupTherapy();

groupTherapySDK.createGroupTherapy = groupTherapySDK.createGroupTherapy.bind(groupTherapySDK);
groupTherapySDK.deleteGroupTherapy = groupTherapySDK.deleteGroupTherapy.bind(groupTherapySDK);
groupTherapySDK.updateGroupTherapyName = groupTherapySDK.updateGroupTherapyName.bind(groupTherapySDK);
groupTherapySDK.updateGroupTherapyMembers = groupTherapySDK.updateGroupTherapyMembers.bind(groupTherapySDK);
groupTherapySDK.getGroupTherapyDetail = groupTherapySDK.getGroupTherapyDetail.bind(groupTherapySDK);
groupTherapySDK.getGroupTherapyList = groupTherapySDK.getGroupTherapyList.bind(groupTherapySDK);
groupTherapySDK.getGroupTherapyMemberList = groupTherapySDK.getGroupTherapyMemberList.bind(groupTherapySDK);
groupTherapySDK.getGroupRegistrationList = groupTherapySDK.getGroupTherapyMemberList.bind(groupTherapySDK);

export default groupTherapySDK;
