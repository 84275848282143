import {all, call, put} from "redux-saga/effects";
import {PresetItemsType} from "../views/PresetsFormContext";
import {getSessionId} from "../../auth/helpers/authStorage";
import {onAutoEnableTracker} from "../../tracker/customTracker/helpers/onAutoEnableTracker";
import {AppConfig} from "app/AppConfig";
import {getPresetTaskBehaviorExperimentPayload} from "../helpers/presetHelpers";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import taskSDK from "../../taskRegistration/helpers/taskSDK";
import {TaskPostBodyRequest} from "@sense-os/goalie-js";
import {toastActions} from "../../toaster/redux";
import strTranslation from "../../assets/lang/strings";

interface SavePresetBehaviorExperimentTaskParams {
	formValues: PresetItemsType;
	userId: number;
}

/**
 * Save Preset Behavior Experiment Task
 * 1. Enable Behavior Experiment
 * 2. Bulk Behavior Experiment Task Creation
 */
export function* savePresetBehaviorExperimentTask(params: SavePresetBehaviorExperimentTaskParams) {
	const {formValues, userId} = params;

	const token: string = yield call(getSessionId);

	/** 1. Enable Behavior Experiment */
	yield call(onAutoEnableTracker, {
		userId,
		trackerId: AppConfig.BEHAVIOR_EXPERIMENT_ID,
	});

	/** 2. Bulk Behavior Experiment Task Creation */
	const payloads: TaskPostBodyRequest<any>[] = getPresetTaskBehaviorExperimentPayload({
		formValues,
		userId,
	});

	if (payloads.length > 0) {
		const mappedCallPayloads = payloads.map((payload) => {
			return apiCallSaga(taskSDK.createTask, token, payload);
		});

		try {
			yield all(mappedCallPayloads);
		} catch (error) {
			yield put(toastActions.addToast({message: strTranslation.GRAPHS.new_event.plan.fail.toast, type: "error"}));
		}
	}
}
