import * as Sentry from "@sentry/react";
import {lazy, useState, useEffect, FC, Suspense} from "react";
import {history as routerHistory} from "../../helpers/routerHistory";
import {Redirect, Route, Switch} from "react-router-dom";
import BrowserWarningDialog from "./../../browserWarning/views/BrowserWarningDialog";
import InternetConnectionAlert from "../../connectionStatus/views/InternetConnectionAlert";
import {Container, CircularProgress} from "@material-ui/core";
import {useSelector, useDispatch} from "react-redux";
import {isBootstrapped, shouldWaitForSortedContacts} from "../../bootstrap/redux/bootstrapSelectors";
import {bootstrapActions} from "../../bootstrap/redux";
import ErrorBoundary from "../../errorHandler/ErrorBoundary";
import {Helmet} from "react-helmet";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {BackdropOfObsolescence} from "../../versionCheck/views/BackdropOfObsolescence";
import {UpdateConfirmationDialog} from "../../versionCheck/views/UpdateConfirmationDialog";
import {AuthenticatorRedirectPage} from "../../twoFactorAuthentication/views/AuthenticatorRedirect";
import {CalendarConnectRedirect} from "../../calendar/views/CalendarConnectRedirect";
import useAuthUser from "../../auth/hooks/useAuthUser";
import {NDProductFruits} from "../../productFruits/NDProductFruits";
import {NDBlogSearchHotkeys} from "../../nicedayBlogSearch/views/NDBlogSearchHotkeys";
import {NDColors} from "../../material-ui/NDColors";
import featureFlags from "../../featureFlags/FeatureFlags";
import strTranslation from "../../assets/lang/strings";
import localization from "../../localization/Localization";
import {canAccessAppMode, canAccessOrgMode} from "../../auth/redux";
import {FreshdeskChat} from "../../freshdeskChat/FreshdeskChat";

const PrimaryLayout = lazy(() => import("./layout/PrimaryLayout"));
const OrganizationLayout = lazy(() => import("./layout/OrganizationLayout"));
const AuthRoute = lazy(() => import("../../auth/AuthRoute"));

export const history = routerHistory;

const App = () => {
	// True if the app is already bootstrapped.
	// We need this value because the `isBootstrapped` value in redux state is unreliable.
	// (The redux state will be reset to initial state when the user log out from the portal
	// and therefore the `isBootstrapped` value could be changed to `false` again.
	const [firstAttemptFinished, setFirstAttemptFinished] = useState<boolean>(false);
	const {isBootstrapped} = useBootstrap();
	const waitForSortedContacts = useSelector(shouldWaitForSortedContacts);
	const title = localization.formatMessage(strTranslation.WEBPAGE.main.title);

	useEffect(() => {
		if (isBootstrapped) {
			setFirstAttemptFinished(true);
		}

		if (featureFlags.portalCleanupV1) {
			// TODO Remove this and update styling from `_global.scss` when portal cleanup v2 is implemented
			document.body.style.background = NDColors.GRADIENT_BACKGROUND;
			document.body.style.backgroundRepeat = "no-repeat";
			document.body.style.backgroundAttachment = "fixed";
		}
	}, [isBootstrapped]);

	// Show loading page before rendering anything
	if ((!isBootstrapped && !firstAttemptFinished) || waitForSortedContacts) {
		return <FullScreenLoadingPage dataTestId="FullScreenLoadingPage" />;
	}

	return (
		<Sentry.ErrorBoundary
			fallback={() => <ErrorBoundary />}
			beforeCapture={(scope) => {
				scope.setTag("domain", SentryTags.Crash);
			}}
		>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content="Niceday Web Application" />
			</Helmet>
			<Suspense fallback={<FullScreenLoadingPage dataTestId="FullScreenLoadingPageSuspense" />}>
				<AppRoute />
			</Suspense>
			<BrowserWarningDialog />
			<InternetConnectionAlert />
			<UpdateConfirmationDialog />
			<BackdropOfObsolescence />
			<NDProductFruits />
			<NDBlogSearchHotkeys />
			{featureFlags.freshchat && <FreshdeskChat />}
		</Sentry.ErrorBoundary>
	);
};

const AppRoute: FC = () => {
	const authUser = useAuthUser();
	const canAccessOrg = useSelector(canAccessOrgMode);
	const canAccessApp = useSelector(canAccessAppMode);

	return (
		<Switch>
			{!authUser && <Route path={"/auth"} component={AuthRoute} />}
			{!!authUser && <Route path={"/app/calendar"} component={CalendarConnectRedirect} />}
			{!!authUser && canAccessApp && <Route path={"/app"} component={PrimaryLayout} />}
			{!!authUser && featureFlags.adminPage && canAccessOrg && (
				<Route path={"/org"} component={OrganizationLayout} />
			)}
			<Route exact path={"/2fa-authenticator"} component={AuthenticatorRedirectPage} />
			{!authUser && <Redirect to="/auth/login" />}
			{!!authUser && <Redirect to="/app" />}
		</Switch>
	);
};

function useBootstrap() {
	const dispatch = useDispatch();
	const bootstrapped = useSelector(isBootstrapped);

	useEffect(() => {
		if (!bootstrapped) {
			dispatch(bootstrapActions.startBootstrapApp());
		}
	}, [dispatch, bootstrapped]);

	return {isBootstrapped: bootstrapped};
}

const FullScreenLoadingPage = ({dataTestId}) => {
	return (
		<Container data-testid={dataTestId}>
			<div style={{margin: "300px auto", display: "flex", flexDirection: "column", alignItems: "center"}}>
				<img src="/assets/img/all-logo/logo.png" style={{minWidth: 100, marginBottom: 20}} />
				<CircularProgress />
			</div>
		</Container>
	);
};

export default Sentry.withProfiler(App);
