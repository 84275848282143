import moment from "moment";
import {createContext} from "../../helpers/contextCreator";
import {ToolboxPreset, PresetTodoRepetition, PresetTodoTimeOption, PresetTodo} from "../presetsTypes";
import {CoreTrackerModelNames} from "../../tracker/utils/coreTracker";
import {CORE_TRACKER_SENSOR_KEY} from "@sense-os/goalie-js";
import {TIME_UNITS} from "constants/time";

export interface PresetItemTodo {
	repetition: PresetTodoRepetition;
	date: Date;
	time: Date;
	timeSecond?: Date;
	timeThird?: Date;
	withNotification: boolean;
}

interface PresetTrackerItem {
	sensorName: string;
	title?: string;
}

export interface PresetPsychoItem {
	title: string;
	link: string;
}

export type PresetBehaviorExperimentTodoType = Record<string, PresetItemTodo>;

export interface PresetBehaviourExperiment {
	defaultTodos: PresetBehaviorExperimentTodoType;
	todos: PresetBehaviorExperimentTodoType;
}

export interface PresetItem {
	isEnabled: boolean;
	todo?: PresetItemTodo;
	defaultTodo?: PresetItemTodo;
	tracker?: PresetTrackerItem;
	psycho?: PresetPsychoItem;
	behaviourExperiments?: PresetBehaviourExperiment;
}

export type PresetItemsType = Record<string, PresetItem>;

interface PresetsFormContext {
	presetItems: PresetItemsType;
	textFilter: string;
}

const initialValue: PresetsFormContext = {
	presetItems: {},
	textFilter: "",
};

const {Provider, createGetStateHook, createSetStateHook} = createContext(initialValue);

/** Form Context Provider */
export const PresetsFormContextProvider = Provider;

const timeOptionToHour = (timeOption: PresetTodoTimeOption): number => {
	switch (timeOption) {
		case PresetTodoTimeOption.MORNING:
			return 12;
		case PresetTodoTimeOption.AFTERNOON:
			return 17;
		case PresetTodoTimeOption.EVENING:
			return 22;
		default:
			return 12;
	}
};

function createTodo(defaultTodo: PresetTodo): PresetItemTodo {
	if (!defaultTodo) return;

	return {
		repetition: defaultTodo.repetition,
		withNotification: true,
		date: moment().add(1, TIME_UNITS.DAY).toDate(),
		time: moment().set(TIME_UNITS.HOURS, timeOptionToHour(defaultTodo.time[0])).set(TIME_UNITS.MINUTES, 0).toDate(),
		timeSecond: !defaultTodo.time[1]
			? undefined
			: moment().set(TIME_UNITS.HOURS, timeOptionToHour(defaultTodo.time[1])).set(TIME_UNITS.MINUTES, 0).toDate(),
		timeThird: !defaultTodo.time[2]
			? undefined
			: moment().set(TIME_UNITS.HOURS, timeOptionToHour(defaultTodo.time[2])).set(TIME_UNITS.MINUTES, 0).toDate(),
	};
}

function createEndNextWeekTodo(): PresetItemTodo {
	return {
		repetition: PresetTodoRepetition.NO_REPETITION,
		withNotification: true,
		date: moment().add(6, TIME_UNITS.DAY).toDate(),
		time: moment()
			.set(TIME_UNITS.HOURS, timeOptionToHour(PresetTodoTimeOption.EVENING))
			.set(TIME_UNITS.MINUTES, 0)
			.toDate(),
	};
}

export enum PresetPrefix {
	TRACKER = "tracker_",
	PSYCHO_EDUCATION = "psycho_",
	BEHAVIOR_EXPERIMENT = "behavior_experiment_",
	THOUGHT_RECORD = "thought_record_",
}

/** Start of Getter of Preset Items Map */
export const usePresetItemsMap = createGetStateHook((state) => state.presetItems);
export const toPresetTrackerId = (sensorName: string) => `${PresetPrefix.TRACKER}${sensorName}`;
export const toPresetPsychoId = (link: string) => `${PresetPrefix.PSYCHO_EDUCATION}${link}`;
export const toPresetBehaviorExperimentId = (id: string) => `${PresetPrefix.BEHAVIOR_EXPERIMENT}${id}`;
export const toPresetThoughtRecordId = (parentId: string) => `${PresetPrefix.THOUGHT_RECORD}${parentId}`;

export const useGetTrackerOfCurrentPreset = (sensorName: string) => {
	const presetItems: PresetItemsType = usePresetItemsMap();
	return presetItems[toPresetTrackerId(sensorName)];
};

export const useGetPsychoOfCurrentPreset = (link: string) => {
	const presetItems: PresetItemsType = usePresetItemsMap();
	return presetItems[toPresetPsychoId(link)];
};

export const useGetThoughRecordOfCurrentPreset = (sensorName: string) => {
	const presetItems: PresetItemsType = usePresetItemsMap();
	return presetItems[toPresetThoughtRecordId(sensorName)];
};

export const useGetBehaviorExperimentOfCurrentPreset = (sensorName: string) => {
	const presetItems: PresetItemsType = usePresetItemsMap();
	return presetItems[toPresetBehaviorExperimentId(sensorName)];
};

export const useGetBehaviorExperimentTodoItem = (itemId: string, todoId: string) => {
	const presetItems: PresetItemsType = usePresetItemsMap();
	return presetItems[itemId].behaviourExperiments.todos[todoId];
};

export const useGetPresetItem = (itemId: string) => {
	const presetItems: PresetItemsType = usePresetItemsMap();
	return presetItems[itemId];
};
/** End of Getter of Preset Items Map */

/** Getter Keyword state of preset */
export const useGetPresetTextFilter = createGetStateHook((state) => state.textFilter);
/** Setter Keyword state of preset */
export const useSetPresetTextFilter = createSetStateHook((state, inputKeyword: string) => {
	state.textFilter = inputKeyword;
});

/** Start of Setter of Preset Items Map */
export const useSetInitialValuePresetsForm = createSetStateHook((state, inputTrackers: ToolboxPreset) => {
	state.presetItems = {};

	/** initial state value of trackers */
	(inputTrackers.trackers || []).forEach((inputTracker) => {
		const trackerId: string = toPresetTrackerId(inputTracker.sensorName);
		state.presetItems[trackerId] = {
			isEnabled: inputTracker.isEnabled,
			todo: createTodo(inputTracker.defaultTodo),
			defaultTodo: createTodo(inputTracker.defaultTodo),
			tracker: {
				sensorName: inputTracker.sensorName,
				title: inputTracker.title,
			},
		};
	});

	/** initial state value of psycho educations */
	(inputTrackers.psychoeducations || []).forEach((psychoeducation) => {
		const psychoId: string = toPresetPsychoId(psychoeducation.link);
		state.presetItems[psychoId] = {
			isEnabled: psychoeducation.isEnabled,
			todo: createEndNextWeekTodo(),
			defaultTodo: createEndNextWeekTodo(),
			psycho: {
				link: psychoeducation.link,
				title: psychoeducation.title,
			},
		};
	});

	/** initial state value of behavior experiment */
	(inputTrackers.behaviourExperiments || []).forEach((behaviorExperiment) => {
		const behaviorExperimentId: string = toPresetBehaviorExperimentId(behaviorExperiment.id as unknown as string);
		const behaviorExperimentTodos: PresetBehaviorExperimentTodoType = behaviorExperiment.defaultTodos.reduce(
			(result, defaultTodo) => {
				result[toPresetBehaviorExperimentId(defaultTodo.id as unknown as string)] = createEndNextWeekTodo();

				return result;
			},
			{},
		);

		state.presetItems[behaviorExperimentId] = {
			isEnabled: behaviorExperiment.isEnabled,
			tracker: {
				sensorName: CoreTrackerModelNames.BehaviorExperiment,
			},
			behaviourExperiments: {
				defaultTodos: behaviorExperimentTodos,
				todos: behaviorExperimentTodos,
			},
		};
	});

	/** initial state value of thought record */
	(inputTrackers.thoughtRecords || []).forEach((thoughtRecord) => {
		const thoughtRecordId: string = toPresetThoughtRecordId(thoughtRecord.sensorName);
		state.presetItems[thoughtRecordId] = {
			isEnabled: thoughtRecord.isEnabled,
			todo: createTodo(thoughtRecord.defaultTodo),
			defaultTodo: createTodo(thoughtRecord.defaultTodo),
			tracker: {
				sensorName: CORE_TRACKER_SENSOR_KEY.GSCHEME,
			},
		};
	});
});

/** reset preset form value */
export const useSetResetPresetForm = createSetStateHook((state) => {
	state.presetItems = {};
});

const useSetIsEnabled = createSetStateHook((state, itemId: string, isEnabled: boolean) => {
	const item: PresetItem = state.presetItems[itemId];
	if (!item || item.isEnabled === isEnabled) return;

	if (!isEnabled) {
		item.isEnabled = false;
	} else {
		item.isEnabled = true;
		if (!!item.defaultTodo) {
			item.todo = {...item.defaultTodo};
		}

		if (!!item.behaviourExperiments) {
			item.behaviourExperiments.todos = {...item.behaviourExperiments.defaultTodos};
		}
	}
});

const useSetRepetition = createSetStateHook((state, itemId: string, repetition: PresetTodoRepetition) => {
	const item: PresetItem = state.presetItems[itemId];
	if (!item || !item.todo || item.todo.repetition === repetition) return;

	item.todo.repetition = repetition;
	if (repetition === PresetTodoRepetition.THRICE_A_DAY && !item.todo.timeThird) {
		item.todo.timeThird = moment()
			.set("hour", timeOptionToHour(PresetTodoTimeOption.EVENING))
			.set("minute", 0)
			.toDate();
	}

	if (
		(repetition === PresetTodoRepetition.THRICE_A_DAY || repetition === PresetTodoRepetition.TWICE_A_DAY) &&
		!item.todo.timeSecond
	) {
		item.todo.timeSecond = moment()
			.set("hour", timeOptionToHour(PresetTodoTimeOption.AFTERNOON))
			.set("minute", 0)
			.toDate();
	}

	if (repetition !== PresetTodoRepetition.THRICE_A_DAY) {
		item.todo.timeThird = undefined;
	}

	if (repetition !== PresetTodoRepetition.TWICE_A_DAY && repetition !== PresetTodoRepetition.THRICE_A_DAY) {
		item.todo.timeSecond = undefined;
	}
});

const useSetTodoDate = createSetStateHook((state, itemId: string, date: Date) => {
	const item: PresetItem = state.presetItems[itemId];
	if (!item || !item.todo) return;

	item.todo.date = date;
});

const useSetTodoTime = createSetStateHook((state, itemId: string, date: Date) => {
	const item: PresetItem = state.presetItems[itemId];
	if (!item || !item.todo) return;

	item.todo.time = date;
});

const useSetTodoTime2 = createSetStateHook((state, itemId: string, date: Date) => {
	const item: PresetItem = state.presetItems[itemId];
	if (!item || !item.todo) return;

	item.todo.timeSecond = date;
});

const useSetTodoTime3 = createSetStateHook((state, itemId: string, date: Date) => {
	const item: PresetItem = state.presetItems[itemId];
	if (!item || !item.todo) return;

	item.todo.timeThird = date;
});

const useSetTodoNotification = createSetStateHook((state, itemId: string, value: boolean) => {
	const item: PresetItem = state.presetItems[itemId];
	if (!item || !item.todo) return;

	item.todo.withNotification = value;
});

const useRemoveTodo = createSetStateHook((state, itemId: string) => {
	const item: PresetItem = state.presetItems[itemId];
	if (!item || !item.todo) return;
	item.todo = undefined;
});

/** Behavior Experiment Setter section */
const useSetTodoBehaviorExperimentDate = createSetStateHook((state, itemId: string, todoId: string, value: Date) => {
	const item: PresetBehaviourExperiment = state.presetItems[itemId].behaviourExperiments;

	if (!item) return;

	item.todos[todoId].date = value;
});

const useSetTodoBehaviorExperimentTime = createSetStateHook((state, itemId: string, todoId: string, value: Date) => {
	const item: PresetBehaviourExperiment = state.presetItems[itemId].behaviourExperiments;

	if (!item) return;

	item.todos[todoId].time = value;
});

const useSetTodoBehaviorExperimentNotification = createSetStateHook(
	(state, itemId: string, todoId: string, value: boolean) => {
		const item: PresetBehaviourExperiment = state.presetItems[itemId].behaviourExperiments;

		if (!item) return;

		item.todos[todoId].withNotification = value;
	},
);

const useSetRemoveBehaviorExperimentTodo = createSetStateHook((state, itemId: string, todoId: string) => {
	const item: PresetBehaviourExperiment = state.presetItems[itemId].behaviourExperiments;

	if (!item) return;

	item.todos[todoId] = undefined;
});
/** End of Behavior Experiment Setter section */

/** End of Setter of Preset Items Map */

/** Main Setter of Preset Items Map */
export const useGetSetterForPresetItem = (itemId: string) => {
	const setIsEnabled = useSetIsEnabled();
	const setRepetition = useSetRepetition();
	const setDate = useSetTodoDate();
	const setTime = useSetTodoTime();
	const setTime2 = useSetTodoTime2();
	const setTime3 = useSetTodoTime3();
	const setNotification = useSetTodoNotification();
	const removeTodo = useRemoveTodo();

	return {
		setIsEnabled: (isEnabled: boolean) => {
			setIsEnabled(itemId, isEnabled);
		},
		setRepetition: (repetition: PresetTodoRepetition) => {
			setRepetition(itemId, repetition);
		},
		setDate: (date: Date) => {
			setDate(itemId, date);
		},
		setTime: (date: Date) => {
			setTime(itemId, date);
		},
		setTime2: (date: Date) => {
			setTime2(itemId, date);
		},
		setTime3: (date: Date) => {
			setTime3(itemId, date);
		},
		setNotification: (withNotification: boolean) => {
			setNotification(itemId, withNotification);
		},
		removeTodo: () => {
			removeTodo(itemId);
		},
	};
};

/** Setter for tracker preset */
export const useGetSetterForTrackerOfPreset = (sensorName: string) =>
	useGetSetterForPresetItem(toPresetTrackerId(sensorName));

/** Setter for psychoeducation preset */
export const useGetSetterForPsychoOfPreset = (link: string) => useGetSetterForPresetItem(toPresetPsychoId(link));

/** Setter for thought record preset */
export const useGetSetterForThoughtRecordOfPreset = (sensorName: string) =>
	useGetSetterForPresetItem(toPresetThoughtRecordId(sensorName));

/** Setter for behavior experiment preset */
export const useGetSetterForBehaviorExperimentOfPreset = (itemId: string, todoId?: string) => {
	const setIsEnabled = useSetIsEnabled();
	const setDate = useSetTodoBehaviorExperimentDate();
	const setTime = useSetTodoBehaviorExperimentTime();
	const setNotification = useSetTodoBehaviorExperimentNotification();
	const removeTodo = useSetRemoveBehaviorExperimentTodo();

	return {
		setIsEnabled: (isEnabled: boolean) => {
			setIsEnabled(itemId, isEnabled);
		},
		setDate: (value: Date) => {
			setDate(itemId, todoId, value);
		},
		setTime: (value: Date) => {
			setTime(itemId, todoId, value);
		},
		setNotification: (value: boolean) => {
			setNotification(itemId, todoId, value);
		},
		removeTodo: () => {
			removeTodo(itemId, todoId);
		},
	};
};
