import {CSSProperties, ComponentType} from "react";
import {GroupTherapyMember, GroupTherapyRegistration, RegistrationType} from "@sense-os/goalie-js";

import ScienceIcon from "../../assets/icons/science.svg";
import TherapySession from "../../assets/icons/therapy-session.svg";
import PsychoEducation from "../../assets/icons/psychoeducation.svg";
import MeetingNotes from "../../assets/icons/meeting-notes.svg";
import {Contact} from "../../contacts/contactTypes";
import {withLocalizedTooltip} from "../../components/LocalizedTooltip";
import strTranslation from "../../assets/lang/strings";

import {GroupRegistrationItem, GroupTherapy} from "../groupTherapy";

/**
 * Get group therapy member ids from given client and therapist contacts
 */
export const getGroupMemberIds = (clients: GroupTherapyMember[], therapists: GroupTherapyMember[]): number[] => {
	const memberIds: number[] = [];
	clients?.forEach((client) => memberIds.push(client.id));
	therapists?.forEach((therapist) => memberIds.push(therapist.id));
	return memberIds;
};

/**
 * Get latest updated member ids of group therapy
 */
export const getUpdatedMemberIds = (existingGroup: GroupTherapy, updatedGroup: GroupTherapy): number[] => {
	let isTherapistsUpdated: boolean = false;
	let isClientsUpdated: boolean = false;

	// Get existing therapist and client ids
	const existingTherapistIds: number[] = existingGroup.therapists?.map((therapist) => therapist.id);
	const existingClientIds: number[] = existingGroup.clients?.map((client) => client.id);

	// Get updated therapist and client ids
	const newTherrapistIds: number[] = updatedGroup.therapists?.map((therapist) => therapist.id);
	const newClientIds: number[] = updatedGroup.clients?.map((client) => client.id);

	if (JSON.stringify(existingTherapistIds) !== JSON.stringify(newTherrapistIds)) {
		// Check if therapist members is updated
		isTherapistsUpdated = true;
	}

	if (JSON.stringify(existingClientIds) !== JSON.stringify(newClientIds)) {
		// Check if client members is updated
		isClientsUpdated = true;
	}

	return isTherapistsUpdated || isClientsUpdated ? newTherrapistIds.concat(newClientIds) : [];
};

/**
 * Get icon and tooltip for registration table item
 */
export const getRegistrationIcon = (type: RegistrationType): ComponentType<{style?: CSSProperties}> => {
	switch (type) {
		case RegistrationType.BEHAVIOR_EXPERIMENT:
			return withLocalizedTooltip(ScienceIcon, strTranslation.ZZ_CORE_TRACKER.tracker.behavior_experiment.name);
		case RegistrationType.HOMEWORK:
			return withLocalizedTooltip(PsychoEducation, strTranslation.ZZ_CORE_TRACKER.tracker.homework.name);
		case RegistrationType.SESSION:
			return withLocalizedTooltip(TherapySession, strTranslation.ZZ_THERAPY_SESSION.name);
		case RegistrationType.SESSION_NOTE:
			return withLocalizedTooltip(MeetingNotes, strTranslation.ZZ_CORE_TRACKER.tracker.meeting_note.name);
	}
};

/**
 * Convert group therapy registration list into registration table item
 */
export const convertRegistrationListIntoRegistrationItems = (
	registrations: GroupTherapyRegistration[],
): GroupRegistrationItem[] => {
	const items: GroupRegistrationItem[] = [];
	registrations.forEach((registration) => {
		items.push({
			title: registration.title,
			dueDate: registration.dueDate,
			hasReminder: !!registration.dueDate,
			Icon: getRegistrationIcon(registration.type),
		});
	});
	return items;
};

/**
 * Convert portal Contact type into GroupTherapyMember
 */
export const convertContactIntoGroupTherapyMember = (contact: Contact): GroupTherapyMember => {
	return {
		id: contact.id,
		firstName: contact.firstName,
		lastName: contact.lastName,
		image: contact.image,
		email: contact.email,
	};
};
