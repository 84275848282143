import moment from "moment";
import {TimeUtils} from "constants/time";
import {UserRole as SDKUserRole} from "@sense-os/goalie-js";
import Localization from "../../localization/Localization";
import {Contact} from "../../contacts/contactTypes";
import {NumberMap} from "services/utils/Maps";
import {AuthUser, AuthUserRole} from "../../auth/authTypes";
import {LoadingState} from "constants/redux";
import {UserlistTabs} from "../sidebarNavTypes";
import {ContactTabPath, Path} from "app/Path";
import {getClientTabMenuRoute, getTherapistTabMenuRoute} from "../../helpers/routeGenerator";
import strTranslation from "../../assets/lang/strings";
import {GroupTherapy} from "../../groupTherapy/groupTherapy";

/**
 * Check logged in user (except client) is able to access Freshdesk or not.
 * @param {AuthUser} authUser
 * @param {LoadingState} tooltipLoadingState
 * @param {boolean} isUserAllowed
 * @returns {boolean}
 */
export const isFreshdeskAccessible = (
	authUser: AuthUser,
	userSettingsLoadingState: LoadingState,
	isUserAllowed: boolean,
) => {
	const hasLocalUserJoinedToOrg = authUser.role !== AuthUserRole.CLIENT && authUser.organization?.isAccepted;
	const isUserSettingsLoaded = userSettingsLoadingState === LoadingState.LOADED;
	return hasLocalUserJoinedToOrg && isUserSettingsLoaded && isUserAllowed;
};

/**
 * Convert timestamp to string time.
 * TODO Remove this when portal cleanup v2 is implemented
 * @param {number} timestamp
 * @returns {string}
 */
export const toStringTime = (timestamp: number): string => {
	if (!timestamp) {
		return "";
	}

	const now: moment.Moment = moment(),
		msgTime: moment.Moment = moment(timestamp);
	const timeString: string = msgTime.format("HH:mm");

	let dateString: string = msgTime.format("DD-MM-YY");

	const dayDiff: number = TimeUtils.getDayDiff(now, msgTime);

	if (dayDiff < 1) {
		dateString = Localization.formatMessage(strTranslation.TIME.today);
	} else if (dayDiff === 1) {
		dateString = Localization.formatMessage(strTranslation.TIME.yesterday);
	}
	return dateString + ", " + timeString;
};

/***
 * Get last interaction string time of given `timestamp`
 */
export const getLastInteractStringTime = (timestamp: number): string => {
	if (!timestamp) {
		return "";
	}

	const now: moment.Moment = moment();
	const msgTime: moment.Moment = moment(timestamp);

	const isToday: boolean = moment(msgTime).isSame(now, "day");
	const isYesterday: boolean = moment(msgTime).isBefore(now, "day");
	const isBeforeYesterday: boolean = moment(now).diff(msgTime, "day") > 1;
	const isSameYear: boolean = moment(msgTime).isSame(now, "year");

	if (isToday) {
		// Return time only
		// `13:20`
		return msgTime.format("HH:mm");
	}

	if (isYesterday) {
		if (isBeforeYesterday) {
			if (!isSameYear) {
				// Return `Month Date, Year` if it's not from same year
				// `Dec 15, 2022`
				return msgTime.format("MMM D, YYYY");
			}
			// Return `Month Date` only if it's before yesterday (like 2 days ago)
			// `Jan 23`
			return msgTime.format("MMM D");
		}

		// Return `yesterday`
		return Localization.formatMessage(strTranslation.TIME.yesterday);
	}
};

/**
 * Calculate unread messages of client / colleagues
 * @param {Contact} contacts
 * @param {NumberMap<number>} unreadMessages
 * @returns {number}
 */
export const calculateUnreadMessages = (contacts: Contact[], unreadMessages: NumberMap<number>): number => {
	return unreadMessages
		? contacts.map(({id}) => unreadMessages[id]).reduce((total, count) => (count ? total + count : total), 0)
		: 0;
};

/**
 * Default selected contact list tab based on user role
 */
const defaultSelectedTabMap = {
	[AuthUserRole.THERAPIST]: UserlistTabs.CLIENTS,
	[AuthUserRole.RESEARCHER]: UserlistTabs.CLIENTS,
	[AuthUserRole.CLIENT]: UserlistTabs.COLLEAGUES,
};

/**
 * Get selected tab according to auth user role.
 * @param {AuthUserRole} authRole
 * @returns {UserlistTabs}
 */
export const getDefaultSelectedTab = (authRole: AuthUserRole): UserlistTabs => {
	return defaultSelectedTabMap[authRole] || UserlistTabs.COLLEAGUES;
};

/**
 * Get contact pathname according to contact's role
 * @param {Contact} contact
 * @returns {string}
 */
export const getContactPathname = (contact: Contact, pathName: string): string => {
	const hashId: string = contact.hashId;
	return contact.role === SDKUserRole.PATIENT
		? getClientPathname(hashId, null, pathName)
		: getTherapistTabMenuRoute(hashId, ContactTabPath.PROFILE);
};

/**
 * Get pathname for client.
 * @param {string} hashId
 * @return {string}
 */
export const getClientPathname = (hashId: string, tab?: string, pathname?: string): string => {
	if (!tab) tab = getTabForClientPathname(pathname);
	return getClientTabMenuRoute(hashId, tab);
};

/**
 * When navigating to a client page without specifying specific tab, this function will
 * pick the appropriate tab to navigate into.
 *
 * If portal navigate from another client's page, select the same tab.
 * If not, pick `data` tab as default.
 */
const getTabForClientPathname = (pathname: string): string => {
	const currentPath = pathname;
	// True if pathname is `/app/client`
	const isInClientPage = currentPath.includes(Path.APP_CLIENT);
	if (isInClientPage) {
		const splitPath = currentPath.split("/");
		// remove app/client/${userHashId}
		const slicedPath = splitPath.slice(4, splitPath.length);
		// merge path string to set active tab to be the same with given pathname
		const finalPath = slicedPath.reduce((prev, curr) => {
			if (prev) return `${prev}/${curr}`;
			return curr;
		}, "");
		return finalPath;
	}

	return ContactTabPath.DATA;
};

/**
 * Filter group list by given search string
 */
export const getFilteredGroupByName = (groups: GroupTherapy[], searchStr: string) => {
	// no text search applied to the group list, so simply return it as it is
	if (!searchStr) {
		return groups;
	}

	// pass a function to map
	return groups.filter((group) => {
		const {name} = group;
		const haystack: string = [name].filter(Boolean).join(" ").toLowerCase();
		if (haystack.indexOf(searchStr.toLowerCase()) > -1) {
			return group;
		}
		return null;
	});
};
