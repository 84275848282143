import {ActionType, createAction, createAsyncAction} from "typesafe-actions";

import {wrapInDispatch} from "../../helpers/wrapInDispatch";
import {GroupRegistrationItem, GroupTherapy} from "../groupTherapy";

export const groupTherapyActions = {
	fetchGroupTherapyList: createAsyncAction(
		"groupTherapyActions/fetchGroupTherapyList",
		"groupTherapyActions/fetchGroupTherapyListSucceed",
		"groupTherapyActions/fetchGroupTherapyListFailed",
	)<void, {groups: GroupTherapy[]}, {error: Error}>(),

	addAGroupTherapy: createAsyncAction(
		"groupTherapyActions/addAGroupTherapy",
		"groupTherapyActions/addAGroupTherapySucceed",
		"groupTherapyActions/addAGroupTherapyFailed",
	)<{group: Omit<GroupTherapy, "id" | "createdAt">}, {group: GroupTherapy}, {error: Error}>(),

	editAGroupTherapy: createAsyncAction(
		"groupTherapyActions/editAGroupTherapy",
		"groupTherapyActions/editAGroupTherapySucceed",
		"groupTherapyActions/editAGroupTherapyFailed",
	)<
		{id: number; groupUpdate: Partial<GroupTherapy>},
		{id: number; group: GroupTherapy},
		{id: number; error: Error}
	>(),

	deleteAGroupTherapy: createAsyncAction(
		"groupTherapyActions/deleteAGroupTherapy",
		"groupTherapyActions/deleteAGroupTherapySucceed",
		"groupTherapyActions/deleteAGroupTherapyFailed",
	)<{id: number}, {id: number}, {error: Error}>(),

	fetchGroupRegistrationList: createAsyncAction(
		"groupTherapyActions/fetchGroupRegistrationList",
		"groupTherapyActions/fetchGroupRegistrationListSucceed",
		"groupTherapyActions/fetchGroupRegistrationListFailed",
	)<{id: number}, {id: number; registrations: GroupRegistrationItem[]}, {error: Error}>(),

	setEditDialogForGroupTherapy: createAction(
		"groupTherapyActions/setEditDialogForGroupTherapy",
		(group: Partial<GroupTherapy>) => ({group}),
	)(),
};

export type GroupTherapyActions = ActionType<typeof groupTherapyActions>;
export const groupTherapyActionsWithDispatch = wrapInDispatch(groupTherapyActions);
