import {getType} from "typesafe-actions";
import analyticsService from "../../analytics/AnalyticsService";
import {getAuthUserExternalId} from "../../auth/helpers/authStorage";
import {groupTherapyActions, GroupTherapyActions} from "./groupTherapyActions";

const trackedActions = [groupTherapyActions.addAGroupTherapy.request].map(getType);

const properties = (action: GroupTherapyActions) => {
	/**
	 * Tracked actions and payload are based on this slab doc:
	 * https://niceday.slab.com/posts/release-planning-group-therapy-v-1-60xb8a7x#hxei7-events
	 */
	switch (action.type) {
		// Group creation
		case getType(groupTherapyActions.addAGroupTherapy.request):
			return {
				createdBy: getAuthUserExternalId(),
				createdDate: new Date(),
				numberOftherapists: action.payload.group.therapists.length || 0,
				numberOfClients: action.payload.group.clients.length || 0,
			};

		// Group edit
		case getType(groupTherapyActions.editAGroupTherapy.request):
			return {
				groupId: action.payload.id,
				updatedBy: getAuthUserExternalId(),
				updatedDate: new Date(),
				updatedNumberOfClients: action.payload.groupUpdate.clients.length || 0,
			};

		// Group delete
		case getType(groupTherapyActions.deleteAGroupTherapy.request):
			return {
				groupId: action.payload.id,
				deletedBy: getAuthUserExternalId(),
				deletedDate: new Date(),
			};

		// TODO add group plan session analytics if possible.
		default:
			return null;
	}
};

export const groupTherapyEvents = analyticsService.trackReduxActions(trackedActions, properties);
